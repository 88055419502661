import get from 'lodash/get';
import memoize from 'lodash/memoize';

import sanitizeArticleLinks from 'sanitizers/sanitizeArticleLinks';
import {
  ArticleLink,
  ContentTab,
  Holiday,
  HolidayStories,
  Keyed,
  StoryTeaser,
  TagLink,
} from 'sharedTypes';

const defaultArray: unknown[] = [];
const defaultObject: object = {};

export default memoize(
  (holiday: unknown): Holiday => ({
    _type: get(holiday, '_type', 'holiday'),
    id: get(holiday, 'id', ''),
    title: get(holiday, 'title', ''),
    slug: get(holiday, 'slug', ''),
    holidayBanner: {
      src: get(holiday, 'holidayBanner.src', ''),
      id: get(holiday, 'holidayBanner.id', ''),
      metadata: get(holiday, 'holidayBanner.metadata'),
    },
    heroIllustration: {
      src: get(holiday, 'heroIllustration.src', ''),
      rteCredit: get(holiday, 'heroIllustration.rteCredit', []),
      caption: get(holiday, 'heroIllustration.caption', ''),
      credit: get(holiday, 'heroIllustration.credit', ''),
      id: get(holiday, 'heroIllustration.id', ''),
      alt: get(holiday, 'heroIllustration.alt', ''),
      metadata: get(holiday, 'heroIllustration.metadata'),
    },
    heroIllustrationMobile: {
      src: get(holiday, 'heroIllustrationMobile.src', ''),
      rteCredit: get(holiday, 'heroIllustration.rteCredit', []),
      caption: get(holiday, 'heroIllustrationMobile.caption', ''),
      credit: get(holiday, 'heroIllustrationMobile.credit', ''),
      id: get(holiday, 'heroIllustrationMobile.id', ''),
      alt: get(holiday, 'heroIllustrationMobile.alt', ''),
      metadata: get(holiday, 'heroIllustrationMobile.metadata'),
    },
    recipeIllustration: get(holiday, 'recipeIllustration'),
    tag: get(holiday, 'tag', defaultObject as TagLink),
    heroLayout: get(holiday, 'heroLayout', 'a'),
    featuredArticles: get(
      holiday,
      'featuredArticles',
      defaultArray as ArticleLink[]
    ),
    briefDescription: get(holiday, 'briefDescription', ''),
    detailedDescription: get(holiday, 'detailedDescription', []),
    aboutHolidayArticle: get(holiday, 'aboutHolidayArticle'),
    startDate: new Date(get(holiday, 'startDate', '')).toISOString(),
    endDate: new Date(get(holiday, 'endDate', '')).toISOString(),
    relatedRecipes: get(holiday, 'relatedRecipes', []),
    contentTabs: get(holiday, 'contentTabs', [])
      .map((tab: unknown) => {
        const title = get(tab, 'title', '' as string);
        const tag = get(tab, 'tag', defaultObject as TagLink);
        const layout = get(tab, 'layout', 'a');
        const articles = sanitizeArticleLinks(
          get(tab, 'articles', defaultArray as ArticleLink[])
        );

        if (!title || !layout || !articles.length) return null;

        return { title, tag, layout, articles };
      })
      .filter((tab): tab is Keyed<ContentTab> => tab !== null),
    recipesSeo: {
      title: get(holiday, 'recipesSeo.title', ''),
      description: get(holiday, 'recipesSeo.description', ''),
      image: {
        src: get(holiday, 'recipesSeo.image.src', ''),
        caption: get(holiday, 'recipesSeo.image.caption', ''),
        id: get(holiday, 'recipesSeo.image.id', ''),
      },
    },

    seo: {
      title: get(holiday, 'seo.title', ''),
      description: get(holiday, 'seo.description', ''),
      image: {
        src: get(holiday, 'seo.image.src', ''),
        caption: get(holiday, 'seo.image.caption', ''),
        credit: get(holiday, 'seo.image.credit', ''),
        id: get(holiday, 'seo.image.id', ''),
        alt: get(holiday, 'seo.image.alt', ''),
        metadata: get(holiday, 'seo.image.metadata'),
      },
    },

    /* TO-DO: [DEPRECATED] Once Holidays v2 is launched this can be removed */
    featuredArticle: get(holiday, 'featuredArticle', {} as ArticleLink),
    featuredArticleTeaserType: get(
      holiday,
      'featuredArticleTeaserType',
      'teaser-text-sm' as StoryTeaser
    ),
    stories1: get(holiday, 'stories1', {} as HolidayStories),
    stories2: get(holiday, 'stories2', {} as HolidayStories),
    stories3: get(holiday, 'stories3', {} as HolidayStories),
  })
);
