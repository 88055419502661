import { useRouter } from 'next/router';
import * as gtm from 'lib/GTM';
import { useContext, ReactNode, useEffect, createContext } from 'react';

interface AnalyticsContextValue {
  /* */
}

const AnalyticsContext = createContext<AnalyticsContextValue | undefined>(
  undefined
);

export const AnalyticsConsumer = AnalyticsContext.Consumer;

export const useAnalytics = () => {
  const ctx = useContext(AnalyticsContext);
  if (!ctx)
    throw new Error(
      'useAnalyticsContext must be used within a AnalyticsProvider'
    );
  return ctx;
};

interface AnalyticsProps {
  children: ReactNode;
}

export const AnalyticsProvider = ({ children }: AnalyticsProps) => {
  const { events } = useRouter();

  const handleRouteChange = (route: string) => {
    // @ts-ignore
    window.twq('track', 'PageView');

    gtm.pageview(route);
  };

  useEffect(() => {
    events.on('routeChangeComplete', handleRouteChange);

    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  const value = {};

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};
