import ImageGroq from './ImageGroq';
import AuthorLinkGroq from './AuthorLinkGroq';
import ArticleContentGroq from './ArticleContentGroq';

const RecipeGroq = `{
  "id": _id,
  _type,
  title,
  slug,
  "seo": {
    "title": seoTitle,
    "description": seoDescription,
    "image": seoImage${ImageGroq}
  },
  "meta": {
    tags[]->{ title, slug },
    brief,
    "releaseDate": coalesce(releaseDate, _createdAt),
    "createdAt": _createdAt,
    "updatedAt": _updatedAt,
    authors[]->${AuthorLinkGroq},
    "categories": categories,
    recipeAuthors,
  },
  "heroImage": heroImage${ImageGroq},
  "featuredImage": featuredImage${ImageGroq},
  intro,
  ${ArticleContentGroq('body')},
  recipeParts,
  recipeYield,
  recipeTime,
  note,
  relatedArticles[]->{
    "id": _id,
    title,
    slug,
    section->{
      slug
    }
  },
  relatedRecipes[]->{
    "id": _id,
    title,
    slug,
    "heroImage": heroImage${ImageGroq},
    "featuredImage": featuredImage${ImageGroq},
    "meta": {
      authors[]->${AuthorLinkGroq},
      recipeAuthors
    }
  },
  relatedHolidays[]->{
    "id": _id,
    title,
    slug
  }
}`;

export default RecipeGroq;
