import ArticleLinkGroq from './ArticleLinkGroq';
import ImageGroq from './ImageGroq';
import PodcastEpisodeGroq from './PodcastEpisodeGroq';
import AuthorLinkGroq from './AuthorLinkGroq';

const WidgetGroq = `
  { 
    'id': _key, 
    'type': _type, 
    'tag': tag->{ 'id': _id, title, slug }, 
    'variant': type, 
    'encyclopediaTerm': encyclopediaTerm->{ definition, pronunciation, term, typeOfWord },
    'items': items[]{ text, 'image': image${ImageGroq} },
    'slides': slides[]{ title, subtitle, text, attribution, caption, 'image': image${ImageGroq} },
    'articles': articles[]->${ArticleLinkGroq},
    'podcastEpisodes': podcastEpisodes[]->${PodcastEpisodeGroq},
    'authors': authors[]->${AuthorLinkGroq},
    text,
    title,
    attribution,
    ... 
  }
`;

export default WidgetGroq;
