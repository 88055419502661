import get from 'lodash/get';

import { ArticleBody, PodcastEpisode } from 'sharedTypes';

const sanitizePodcastEpisodeResponse = (data: unknown): PodcastEpisode => ({
  _type: get(data, '_type', 'podcastEpisode'),
  id: get(data, 'id', ''),
  slug: get(data, 'slug', ''),
  date:
    get(data, 'releaseDate', '') ||
    get(data, 'date', '') ||
    get(data, 'createdAt', ''),
  releaseDate: get(data, 'releaseDate', ''),
  createdAt: get(data, 'createdAt', ''),
  updatedAt: get(data, 'updatedAt', ''),
  title: get(data, 'title', ''),
  dek: get(data, 'dek', ''),
  description: get(data, 'description', ''),
  content: get(data, 'content', [] as ArticleBody[]),
  listenOn: get(data, 'listenOn', []),
  image: {
    src: get(data, 'image.src', ''),
    caption: get(data, 'image.caption', ''),
    credit: get(data, 'image.credit', ''),
    id: get(data, 'image.id', ''),
    alt: get(data, 'image.alt', ''),
    metadata: get(data, 'image.metadata'),
  },
  podcast: {
    _type: get(data, '_type', 'podcast'),
    id: get(data, 'podcast.id', ''),
    slug: get(data, 'podcast.slug', ''),
    date:
      get(data, 'releaseDate', '') ||
      get(data, 'date', '') ||
      get(data, 'createdAt', ''),
    title: get(data, 'podcast.title', ''),
    dek: get(data, 'podcast.dek', ''),
    description: get(data, 'podcast.description', ''),
    credits: get(data, 'podcast.credits', []),
    subscribeLink: get(data, 'podcast.subscribeLink', ''),
    chronologicalOrder: get(data, 'podcast.chronologicalOrder', false),
    image: {
      src: get(data, 'podcast.image.src', ''),
      caption: get(data, 'podcast.image.caption', ''),
      credit: get(data, 'podcast.image.credit', ''),
      id: get(data, 'podcast.image.id', ''),
      alt: get(data, 'podcast.image.alt', ''),
      metadata: get(data, 'podcast.image.metadata'),
    },
    seo: {
      title: get(data, 'podcast.seo.title', ''),
      description: get(data, 'podcast.seo.description', ''),
      image: {
        src: get(data, 'podcast.seo.image.src', ''),
        caption: get(data, 'podcast.seo.image.caption', ''),
        credit: get(data, 'podcast.seo.image.credit', ''),
        id: get(data, 'podcast.seo.image.id', ''),
        alt: get(data, 'podcast.seo.image.alt', ''),
        metadata: get(data, 'podcast.seo.image.metadata'),
      },
    },
  },
  url: get(data, 'url', ''),
  seo: {
    title: get(data, 'seo.title', ''),
    description: get(data, 'seo.description', ''),
    image: {
      src: get(data, 'seo.image.src', ''),
      caption: get(data, 'seo.image.caption', ''),
      credit: get(data, 'seo.image.credit', ''),
      id: get(data, 'seo.image.id', ''),
      alt: get(data, 'seo.image.alt', ''),
      metadata: get(data, 'seo.image.metadata'),
    },
  },
});

export default sanitizePodcastEpisodeResponse;
