import memoize from 'lodash/memoize';

import sanitizeArticleLink from 'sanitizers/sanitizeArticleLink';

import { ArticleLink } from 'sharedTypes';

export default memoize((articles: unknown): ArticleLink[] => {
  if (!Array.isArray(articles)) {
    return [];
  }

  return articles.map((article: unknown) => sanitizeArticleLink(article));
});
