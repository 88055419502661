import { useEffect } from 'react';
interface BaseProps {
  className?: string;
}

export const TabletLogoLoader: React.FC<BaseProps> = () => {
  useEffect(() => {
    document.querySelector('body')?.classList.add('disable-scroll');
    document.querySelector('html')?.classList.add('disable-scroll');

    return () => {
      document.querySelector('body')?.classList.remove('disable-scroll');
      document.querySelector('html')?.classList.remove('disable-scroll');
    };
  }, []);

  return (
    <div className="TabletLogoLoader bg-color-beige vw100 vh100">
      <svg className="TabletLogoLoader__svg" width="75px" height="75px">
        <path
          id="T"
          d="M6.56,32.35V16.73h61.5v15.62h-6.83v-4.39c0-3.71-2.15-5.86-5.86-5.86H45.7v24.4c0,3.71,2.15,5.86,5.86,5.86h3.9v5.37H20.32
            v-5.37h3.9c3.71,0,5.86-2.15,5.86-5.86V22.1H19.25c-3.71,0-5.86,2.15-5.86,5.86v4.39H6.56z"
          fill="#222222"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="0 37.5 37.5"
            to="1080 37.5 37.5"
            dur="3s"
            begin="0s"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines=".27 0 .6 1"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  );
};
