import { Maybe } from 'types';

/**
 * definitely.
 *
 * @param {Maybe<T>[] | null} an array of T, null, or undefined
 * @returns {T[]} a type-safe array of T, without null or undefined
 */
export function definitely<T>(items?: Maybe<T>[] | null): T[] {
  if (!items) return [];
  return items.filter((i): i is T => Boolean(i));
}
