export const curlyQuotes = (str?: string): string =>
  str
    ? str
        .replace(/([\w.,!?%;'’]{1,3}?)"/gi, '$1”')
        // replace straight single quotes followed by a word character
        .replace(/"(['‘]{0,1}\w)/gi, '“$1')
        // replace straight single quotes preceded by a word character
        .replace(/([\w.,!?;%'‘]{1,3}?)'/gi, '$1’')
        // replace straight single quotes followed by a word character
        .replace(/'(\w)/gi, '‘$1')
    : '';
