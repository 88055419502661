import ImageGroq from './ImageGroq';
import { recipeTeaserGroq } from './RecipesPageGroq';
import { Season } from 'sharedTypes';

export const recipesBySeasonPageQuery = (season: Season) => `{
  "recipes": *[_type == 'recipeArticle' && season.${season} == true]{
    ${recipeTeaserGroq}
  } | order(_createdAt desc),
  "recipesPage": *[_type == "recipesPage"]{
    "recipeIllustration": recipeIllustration${ImageGroq},
    illustrationIsTransparent,
  }[0]
}
`;
