import memoize from 'lodash/memoize';

import { Redirect } from 'sharedTypes';
import sanitizeRedirectResponse from './sanitizeRedirectResponse';

const defaultData: Redirect[] = [];

export default memoize((response: unknown): Redirect[] => {
  const data = Array.isArray(response) ? response : defaultData;

  return data.filter((item) => item !== null).map(sanitizeRedirectResponse);
});
