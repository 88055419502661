import React from 'react';
import { useRouter } from 'next/router';
import { ComponentType, createContext, FC, ReactNode, useContext } from 'react';
import { BaseData } from '../sharedTypes';

export interface SiteSettingsContextValue extends BaseData {
  preview?: boolean;
}

const SiteSettingsContext = createContext<SiteSettingsContextValue | undefined>(
  undefined
);

export const SiteSettingsConsumer = SiteSettingsContext.Consumer;

export const useSiteSettings = () => {
  const ctx = useContext(SiteSettingsContext);
  if (!ctx)
    throw new Error(
      'useSiteSettingsContext must be used within a SiteSettingsProvider'
    );
  return ctx;
};

export const withSiteSettings =
  <P extends object>(
    Component: ComponentType<P>
  ): FC<Omit<P, keyof SiteSettingsContextValue>> =>
  /* eslint-disable react/display-name */
  (props) =>
    (
      <SiteSettingsConsumer>
        {(value) => <Component {...(props as P)} {...value} />}
      </SiteSettingsConsumer>
    );

interface SiteSettingsProps {
  children: ReactNode;
  baseData: BaseData;
  preview?: boolean;
}

export const SiteSettingsProvider = ({
  baseData,
  preview,
  children,
}: SiteSettingsProps) => {
  const router = useRouter();

  if (!baseData) {
    throw new Error(
      `Base data was not fetched for route: '${router.route}'. Did you forget to wrap your getServerSideProps with withBaseData()?`
    );
  }

  const value = {
    ...baseData,
    preview,
  };

  return (
    <SiteSettingsContext.Provider value={value}>
      {children}
    </SiteSettingsContext.Provider>
  );
};
