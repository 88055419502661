import memoize from 'lodash/memoize';
import { compareDesc } from 'date-fns';

import { ArticleLink } from 'sharedTypes';

export default memoize((articles: ArticleLink[]): ArticleLink[] => {
  return articles
    .filter((article: ArticleLink) => {
      return compareDesc(new Date(article.releaseDate), new Date()) > 0;
    })
    .sort((articleA: ArticleLink, articleB: ArticleLink) =>
      compareDesc(
        new Date(articleA.releaseDate),
        new Date(articleB.releaseDate),
      ),
    );
});
