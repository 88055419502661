export type Colors =
  | 'black'
  | 'gray-darkest'
  | 'gray-darker'
  | 'gray-dark'
  | 'gray'
  | 'gray-light'
  | 'gray-lighter'
  | 'gray-lightest-opacity-50'
  | 'off-white'
  | 'white'
  | 'beige-light'
  | 'beige'
  | 'red'
  | 'fiction-pink'
  | 'fiction-blue'
  | 'transparent'
  | 'sand';

export enum HexColors {
  'black' = 'var(--black)',
  'gray-darkest' = 'var(--gray-darkest)',
  'gray-darker' = 'var(--gray-darker)',
  'gray-dark' = 'var(--gray-dark)',
  'gray' = 'var(--gray)',
  'gray-light' = 'var(--gray-light)',
  'gray-lighter' = 'var(--gray-lighter)',
  'gray-lightest-opacity-50' = 'var(--gray-lightest-opacity-50)',
  'off-white' = 'var(--off-white)',
  'white' = 'var(--white)',
  'beige-light' = 'var(--beige-light)',
  'beige' = 'var(--beige)',
  'red' = 'var(--red)',
  'fiction-pink' = 'var(--fiction-pink)',
  'fiction-blue' = 'var(--fiction-blue)',
  'transparent' = 'var(--transparent)',
  'collection-background-grey' = 'var(--collection-background-grey)',
  'sand' = 'var(--sand)',
}
