import ArticleContentGroq from './ArticleContentGroq';
import AuthorLinkGroq from './AuthorLinkGroq';
import ImageGroq from './ImageGroq';

const FeatureArticlePageGroq = `{
  "id": _id,
  title,
  hideHeadline,
  dek,
  slug,
  authors[]->${AuthorLinkGroq},
  releaseDate,
  "heroImage": heroImage${ImageGroq},
  "mobileHeroImage": mobileHeroImage${ImageGroq},
  useArticleHeaderHero,
  isHighDefImage,
  "content": { ${ArticleContentGroq('body')} },
  "seo": {
    "title": seoTitle,
    "description": seoDescription,
    "image": seoImage${ImageGroq}
  },
  backgroundColor,
  progressBarColor,
  bodyTextColor,
  footerLogoTheme,
}`;

export default FeatureArticlePageGroq;
