const ImageGroq = `{
  'src': asset->url,
  alt,
  credit,
  rteCredit,
  caption, 
  crop, 
  hotspot, 
  'id': asset->_id, 
  "metadata": asset->metadata{
    dimensions
  }
}`;

export default ImageGroq;
