import memoize from 'lodash/memoize';
import { compareDesc } from 'date-fns';

import sanitizeArticleLinks from 'sanitizers/sanitizeArticleLinks';
import { ArticleLink } from 'sharedTypes';

export default memoize((data: unknown[]): ArticleLink[][] => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((sectionArticles: unknown) => {
    const sanitizedArticles = sanitizeArticleLinks(sectionArticles);

    return sanitizedArticles
      .filter((article) => {
        return compareDesc(new Date(article.releaseDate), new Date()) > 0;
      })
      .sort((a, b) => {
        return compareDesc(new Date(a.releaseDate), new Date(b.releaseDate));
      })
      .slice(0, 3);
  });
});
