import { SanityUpdateClient } from 'lib/SanityClient';

const buildPatches: any = (docs: any[]) => {
  return docs
    .map((doc: any) => {
      if (doc.content.body[0].image.id) {
        return {
          id: doc.id,
          patch: {
            set: {
              heroImage: {
                _type: 'image',
                caption: doc.content.body[0].image.caption,
                credit: doc.content.body[0].image.credit,
                alt: doc.content.body[0].image.alt,
                asset: {
                  _type: 'reference',
                  _ref: doc.content.body[0].image.id,
                },
              },
              body: doc.content.body.slice(1),
            },
            // this will cause the transaction to fail if the documents has been
            // modified since it was fetched.
            // ifRevisionID: doc._rev
          },
        };
      }
    })
    .filter((patch) => patch);
};

const createTransaction: any = (patches: any) =>
  patches.reduce(
    (tx: any, patch: any) => tx.patch(patch.id, patch.patch),
    SanityUpdateClient.transaction()
  );

const commitTransaction: any = (tx: any) => tx.commit();

export const migrateHeroImages = async (docs: any[]): Promise<null> => {
  const patches = buildPatches(docs);
  if (patches.length === 0) {
    console.log('No more documents to migrate!');
    return null;
  }
  console.log(
    `Migrating batch:\n %s`,
    patches
      .map((patch: any) => `${patch.id} => ${JSON.stringify(patch.patch)}`)
      .join('\n')
  );

  const transaction = createTransaction(patches);
  return await commitTransaction(transaction);
};
