import get from 'lodash/get';

interface EventData {
  event: string;
  event_label: string;
  event_category: string;
  event_value: string;
}

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const pageview = (url: string) => {
  const dataLayer = get(window, 'dataLayer') as unknown as any[];
  if (dataLayer) {
    dataLayer.push({
      event: 'pageview',
      page: url,
    });
  }
};

export const clickTrigger = (eventData: EventData) => {
  const dataLayer = get(window, 'dataLayer') as unknown as any[];
  if (dataLayer) {
    dataLayer.push({
      event: eventData.event,
      event_label: eventData.event_label,
      event_category: eventData.event_category,
      event_value: eventData.event_value,
    });
  }
};
