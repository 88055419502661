import { format, parse, addMinutes } from 'date-fns';

export const dateRoundedToDay = (date = new Date()): Date => {
  const day = format(date, 'LLLL dd yyyy');
  const dayAsDate = parse(day, 'LLLL dd yyyy', new Date());

  return dayAsDate;
};

export default dateRoundedToDay;

const MINUTES_10 = 600;
const MINUTES_60 = 3600;

export const calculateFormat = (s: number) => {
  if (s < MINUTES_10) {
    return 'm:ss';
  }

  if (s < MINUTES_60) {
    return 'mm:ss';
  }

  return 'H:mm:ss';
};

export const formatSeconds = (s: number, timeFormat?: string) => {
  let dt = new Date(s * 1000);
  dt = addMinutes(dt, dt.getTimezoneOffset());

  if (timeFormat) {
    return format(dt, timeFormat);
  }

  return format(dt, calculateFormat(s));
};
