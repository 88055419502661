import React from 'react';

import cx from 'classnames';
import Language from 'constants/Language';
import { Colors } from 'constants/Colors';
import { Button } from 'components/base';
import { FacebookIcon, InstagramIcon, XIcon } from 'components/icons';
import { ExternalLinksRouteMap } from 'constants/RouteMap';

interface Props {
  className?: string;
  color: Colors;
  iconSpacingClassName?: string;
  twitterIconClassName?: string;
  facebookIconClassName?: string;
  instagramIconClassName?: string;
}

const SocialMediaLinks: React.FC<Props> = ({
  className,
  color,
  iconSpacingClassName = 'mr1_25',
  twitterIconClassName = '',
  facebookIconClassName = '',
  instagramIconClassName = '',
}) => {
  const classes = cx(`SocialMediaLinks`, className);

  return (
    <div className={classes}>
      <Button
        containerClassName={iconSpacingClassName}
        ariaLabel={Language.t('SocialMediaLinks.socialMediaButtonAriaLabel', {
          socialMedia: 'Twitter',
        })}
        className="bg-color-transparent"
        to={ExternalLinksRouteMap.TWITTER.path}
      >
        <XIcon className={twitterIconClassName} color={color} />
      </Button>
      <Button
        containerClassName={iconSpacingClassName}
        ariaLabel={Language.t('SocialMediaLinks.socialMediaButtonAriaLabel', {
          socialMedia: 'Facebook',
        })}
        className="bg-color-transparent"
        to={ExternalLinksRouteMap.FACEBOOK.path}
      >
        <FacebookIcon className={facebookIconClassName} color={color} />
      </Button>
      <Button
        ariaLabel={Language.t('SocialMediaLinks.socialMediaButtonAriaLabel', {
          socialMedia: 'Instagram',
        })}
        className="bg-color-transparent"
        to={ExternalLinksRouteMap.INSTAGRAM.path}
      >
        <InstagramIcon className={instagramIconClassName} color={color} />
      </Button>
    </div>
  );
};

export default SocialMediaLinks;
