import get from 'lodash/get';

import { GenericPage } from 'sharedTypes';

const sanitizeGenericPageResponse = (data: unknown): GenericPage => {
  return {
    _type: get(data, '_type', 'genericPage'),
    title: get(data, 'title', ''),
    slug: get(data, 'slug', ''),
    blocks: get(data, 'blocks', []),
    seo: {
      title: get(data, 'seo.title', ''),
      description: get(data, 'seo.description', ''),
      image: {
        src: get(data, 'seo.image.src', ''),
        caption: get(data, 'seo.image.caption', ''),
        credit: get(data, 'seo.image.credit', ''),
        id: get(data, 'seo.image.id', ''),
        alt: get(data, 'seo.image.alt', ''),
        metadata: get(data, 'seo.image.metadata'),
      },
    },
  };
};

export default sanitizeGenericPageResponse;
