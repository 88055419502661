import get from 'lodash/get';
import { SectionLink, ArticleLink } from 'sharedTypes';

interface ReferencedArticle extends Pick<ArticleLink, 'slug'> {
  section: Pick<SectionLink, 'slug'>;
}

const generateArticleUrl = (articleLink: ReferencedArticle): string => {
  return `/sections/${get(articleLink, 'section.slug', '')}/articles/${get(
    articleLink,
    'slug',
    ''
  )}`;
};

export default generateArticleUrl;
