import {
  GetStaticPropsContext,
  GetStaticProps,
  GetStaticPropsResult,
  GetStaticPaths,
  GetServerSideProps,
  GetServerSidePropsContext,
} from 'next';
import { Router } from 'next/router';
import { ArticleStub, PodcastEpisodeStub } from 'types';
import { ParsedUrlQuery } from 'querystring';
import generateArticleUrl from 'utils/generateArticleUrl';
import generatePodcastEpisodeUrl from 'utils/generatePodcastEpisodeUrl';
import { undefinedToNull } from './parsing';
import ApiClient from 'lib/ApiClient';
import { useEffect, useState } from 'react';
import { BaseData } from 'sharedTypes';
import sanitizeGlobalSettingsResponse from 'sanitizers/sanitizeGlobalSettingsResponse';
import sanitizeArticleLinks from 'sanitizers/sanitizeArticleLinks';
import sanitizeRecentArticlesBySection from 'sanitizers/sanitizeRecentArticlesBySection';
import * as FeatureFlags from '../utils/featureFlags';

// if we need to do any other sanitization,
// compose or pipe them here
export const sanitizeServerProps = undefinedToNull;

export interface PageProps {
  baseData: BaseData;
  preview?: boolean;
  [key: string]: any;
}

export const hasLatestStoriesFeatureFlag = FeatureFlags.isEnabled(
  FeatureFlags.Flags.HOMEPAGE_LATEST_STORIES
);

export const getPreviewParam = (
  { preview, previewData }: GetStaticPropsContext,
  previewRoute?: string
): string => {
  if (!preview) {
    return '';
  }

  const { previewId = '', slug } = previewData as {
    previewId: string;
    slug: string;
  };

  if (previewRoute && previewRoute !== slug) {
    return '';
  }

  if (previewId === '') {
    console.warn('previewId was found empty!');
  }

  return previewId;
};

export const redirect = (to: string) => {
  return {
    props: {},
    redirect: {
      permanent: false,
      destination: to,
    },
  };
};

export const legacyArticleOrPodcastEpisodeRedirect = (
  articleOrPodcast: PodcastEpisodeStub | ArticleStub
) => {
  if (articleOrPodcast._type === 'article') {
    const url = generateArticleUrl(articleOrPodcast);
    return redirect(url);
  } else if (articleOrPodcast._type === 'podcastEpisode') {
    const { slug, podcast } = articleOrPodcast;
    if (!podcast) {
      throw new Error(
        `Legacy podcast episode "${articleOrPodcast.slug}" does not have an assigned Podcast`
      );
    }
    const url = generatePodcastEpisodeUrl(podcast.slug, slug);
    return redirect(url);
  } else {
    throw new Error(
      // @ts-expect-error
      `"${articleOrPodcast._type}" is not a valid type for this route`
    );
  }
};
/**
 * Every page needs to load global content (such as nav menu settings).
 * Use this to wrap your page `getServerSideProps` function so this data is
 * included on all pages.
 */

export const withBaseData =
  <
    Result extends { [key: string]: any },
    Params extends ParsedUrlQuery = ParsedUrlQuery
  >(
    gssp: GetStaticProps<Result, Params>
  ) =>
  async (
    ctx: GetStaticPropsContext<Params>
  ): Promise<GetStaticPropsResult<Result>> => {
    const [result, baseData] = await Promise.all([
      gssp(ctx),
      ApiClient.fetchBase(),
    ]);
    if ('redirect' in result) {
      return result;
    }
    if ('notFound' in result && result.notFound) {
      return result;
    }

    return sanitizeServerProps({
      revalidate: 3600,
      ...result,
      props: {
        // typescript doesn't know the && result.notFound check above
        // means that .props definitely exist at this point
        // @ts-ignore
        ...result.props,
        preview: ctx.preview,
        baseData: {
          ...baseData,
          globalSettings: sanitizeGlobalSettingsResponse(
            baseData.globalSettings
          ),
          ...(hasLatestStoriesFeatureFlag
            ? { latestArticles: sanitizeArticleLinks(baseData.latestArticles) }
            : {}),
          recentArticlesBySection: sanitizeRecentArticlesBySection(
            baseData.recentArticlesBySection
          ),
        },
      },
    });
  };

// Article Pages
export const withStaticBaseData =
  <
    Props extends { [key: string]: any },
    Params extends ParsedUrlQuery = ParsedUrlQuery
  >(
    gssp: GetStaticProps<Props, Params>
  ) =>
  async (ctx: GetStaticPropsContext<Params>) => {
    // add this to respect vercel.json values.
    // ctx.res.setHeader('Cache-Control', 'stale-while-revalidate');
    const [result, baseData] = await Promise.all([
      gssp(ctx),
      ApiClient.fetchBase(),
    ]);
    if ('redirect' in result) {
      return result;
    }
    if ('notFound' in result && result.notFound) {
      return result;
    }

    return sanitizeServerProps({
      revalidate: 604800,
      ...result,
      props: {
        // typescript doesn't know the && result.notFound check above
        // means that .props definitely exist at this point
        // @ts-ignore
        ...result.props,
        preview: ctx.preview,
        baseData: {
          ...baseData,
          globalSettings: sanitizeGlobalSettingsResponse(
            baseData.globalSettings
          ),
          ...(hasLatestStoriesFeatureFlag
            ? { latestArticles: sanitizeArticleLinks(baseData.latestArticles) }
            : {}),
          recentArticlesBySection: sanitizeRecentArticlesBySection(
            baseData.recentArticlesBySection
          ),
        },
      },
    });
  };

export const withServerSideBaseData =
  <
    Props extends { [key: string]: any },
    Params extends ParsedUrlQuery = ParsedUrlQuery
  >(
    gssp: GetServerSideProps<Props, Params>
  ) =>
  async (ctx: GetServerSidePropsContext<Params>) => {
    // add this to respect vercel.json values.
    ctx.res.setHeader('Cache-Control', 'stale-while-revalidate');
    const [result, baseData] = await Promise.all([
      gssp(ctx),
      ApiClient.fetchBase(),
    ]);
    if ('redirect' in result) {
      return result;
    }
    if ('notFound' in result && result.notFound) {
      return result;
    }

    return sanitizeServerProps({
      ...result,
      props: {
        // typescript doesn't know the && result.notFound check above
        // means that .props definitely exist at this point
        // @ts-ignore
        ...result.props,
        preview: ctx.preview,
        baseData: {
          ...baseData,
          globalSettings: sanitizeGlobalSettingsResponse(
            baseData.globalSettings
          ),
          ...(hasLatestStoriesFeatureFlag
            ? { latestArticles: sanitizeArticleLinks(baseData.latestArticles) }
            : {}),
          recentArticlesBySection: sanitizeRecentArticlesBySection(
            baseData.recentArticlesBySection
          ),
        },
      },
    });
  };

/**
 * Seeding with arbitrary path data
 */
export const withValidEmptyPaths =
  (params: any): GetStaticPaths =>
  () => {
    return {
      paths: [{ params }],
      fallback: 'blocking',
    };
  };

export const useRouterLoading = () => {
  const [loading, setLoading] = useState(false);

  const startLoading = () => setLoading(true);
  const endLoading = () => setLoading(false);

  useEffect(() => {
    Router.events.on('routeChangeStart', startLoading);
    Router.events.on('routeChangeComplete', endLoading);
    Router.events.on('routeChangeError', endLoading);

    return () => {
      Router.events.off('routeChangeStart', startLoading);
      Router.events.off('routeChangeComplete', endLoading);
      Router.events.off('routeChangeError', endLoading);
    };
  }, []);

  return loading;
};
