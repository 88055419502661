import ImageGroq from './ImageGroq';

const SectionLinkGroq = `{
  _id,
  _type,
  slug,
  title,
  "images": {
    "dark": darkLogo${ImageGroq},
    "light": lightLogo${ImageGroq},
    "blue": blueLogo${ImageGroq}
  },
}`;

export default SectionLinkGroq;
