import React, { Component } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { Img } from 'components/base';
import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';

import { Image } from 'sharedTypes';

const Flickity =
  typeof window === 'undefined' ? () => null : require('flickity');

interface PassProps {
  images?: Image[];
  className?: string;
  imageClassName?: string;
  pageDots?: boolean;
  wrapAround?: boolean;
  children?: React.ReactNode;
  imagesHasLoaded?: boolean;
  itemLength?: number;
}

type Props = PassProps & WithBreakpointsProps;

interface State {
  images: Image[] | null;
  activeIndex: number;
  loadedImg: boolean[];
}

const defaultProps = {
  className: '',
  imageClassName: '',
  pageDots: true,
  wrapAround: false,
  imagesHasLoaded: false,
};

class ImgCarousel extends Component<Props, State> {
  static defaultProps = defaultProps;

  wrapper: Element | null = null;
  flickity: typeof Flickity | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      images: props.images ? props.images : null,
      activeIndex: 0,
      loadedImg: [],
    };
  }

  handleResize = () => {
    if (this.flickity) {
      this.flickity.destroy();
    }

    this.initializeCarousel();
  };

  debounceHandleResize = debounce(this.handleResize, 300);

  componentDidMount() {
    this.initializeCarousel();
    window.addEventListener('resize', this.debounceHandleResize);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { images, currentBreakpoint, imagesHasLoaded } = this.props;
    const { loadedImg } = this.state;

    if (prevProps.currentBreakpoint !== currentBreakpoint) {
      this.handleResize();
    }

    if (!prevProps.imagesHasLoaded && imagesHasLoaded) {
      this.handleResize();
    }

    if (
      images &&
      images.length <= loadedImg.length &&
      prevState.loadedImg.length !== loadedImg.length
    ) {
      this.handleResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceHandleResize);
  }

  initializeCarousel = () => {
    if (this.wrapper) {
      this.flickity = new Flickity(this.wrapper, {
        initialIndex: 0,
        wrapAround: this.props.wrapAround,
        draggable: true,
        freeScroll: false,
        prevNextButtons: true,
        accessibility: true,
        pageDots: this.props.pageDots,
        on: {
          ready: () => {
            setTimeout(() => this.flickity && this.flickity.resize(), 0);
          },
          change: (index?: number) => {
            if (index) {
              this.setState({ activeIndex: index });
            }
          },
        },
      });
    }
  };

  handleImgLoad = () => {
    this.setState((state) => ({ loadedImg: state.loadedImg.concat([true]) }));
  };

  render() {
    const { imageClassName, className, children, itemLength } = this.props;
    const { images } = this.state;

    return (
      <div
        className={cx('ImgCarousel w100 overflow-hidden', className, {
          'ImgCarousel--multi-image': itemLength && itemLength > 1,
        })}
        ref={(node) => (this.wrapper = node)}
      >
        {images &&
          images.map((image: Image) => (
            <Img
              key={image.src}
              className={cx('w100', imageClassName)}
              src={image.src}
              alt={image.alt ? image.alt : get(image, 'caption', '')}
              onImgLoad={this.handleImgLoad}
            />
          ))}
        {children && children}
      </div>
    );
  }
}

export default withBreakpoints<Props>(ImgCarousel);
