import { useReducer } from 'react';
import { Status } from 'types';
import { GridItem } from 'sharedTypes';
import { Paginated } from 'types';
import ApiClient from 'lib/ApiClient';

/* Actions */
enum SearchActionType {
  SEARCH = 'SEARCH',
  SEARCH_MORE = 'SEARCH_MORE',
  SEARCH_SUCCESS = 'SEARCH_SUCCESS',
  SEARCH_REJECTED = 'SEARCH_REJECTED',
}

interface SearchInitAction {
  type: SearchActionType.SEARCH;
  searchTerm: string;
}

interface SearchMoreAction {
  type: SearchActionType.SEARCH_MORE;
}

interface SearchSuccessAction {
  type: SearchActionType.SEARCH_SUCCESS;
  results: Paginated<GridItem>;
}

interface SearchRejectedAction {
  type: SearchActionType.SEARCH_REJECTED;
  errorMessage: string;
}

type SearchAction =
  | SearchInitAction
  | SearchMoreAction
  | SearchSuccessAction
  | SearchRejectedAction;

/**
 * State
 */
interface SearchState {
  searchTerm: string | null;
  results: Paginated<GridItem> | null;
  status: Status;
  errorMessage: string | null;
}

const initialState: SearchState = {
  searchTerm: null,
  results: null,
  status: Status.IDLE,
  errorMessage: null,
};

/**
 * Reducer
 */
const searchReducer = (
  state: SearchState = initialState,
  action: SearchAction
): SearchState => {
  switch (action.type) {
    case SearchActionType.SEARCH:
      return {
        ...state,
        results: null,
        searchTerm: action.searchTerm,
        status: Status.PENDING,
      };
    case SearchActionType.SEARCH_MORE:
      return {
        ...state,
        status: Status.PENDING_MORE,
      };
    case SearchActionType.SEARCH_SUCCESS:
      return {
        ...state,
        status: Status.FULFILLED,
        results: action.results,
      };
    case SearchActionType.SEARCH_REJECTED:
      return {
        ...state,
        status: Status.REJECTED,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};

export const useSearchState = () => {
  const [state, dispatch] = useReducer(searchReducer, initialState);

  const search = async (searchTerm: string) => {
    dispatch({ type: SearchActionType.SEARCH, searchTerm });
    try {
      const results = await ApiClient.search(searchTerm);
      dispatch({ type: SearchActionType.SEARCH_SUCCESS, results });
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : 'There was a problem with your search';
      dispatch({ type: SearchActionType.SEARCH_REJECTED, errorMessage });
    }
  };

  const searchMore = async () => {
    dispatch({ type: SearchActionType.SEARCH_MORE });
    const { searchTerm, results } = state;
    if (!searchTerm || !results) {
      throw new Error(
        `You called "searchMore" on an uninitialized search. Make sure you call this only after a successful search.`
      );
    }
    try {
      const nextResults = await ApiClient.search(searchTerm, results);
      dispatch({ type: SearchActionType.SEARCH_SUCCESS, results: nextResults });
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : 'There was a problem with your search';
      dispatch({ type: SearchActionType.SEARCH_REJECTED, errorMessage });
    }
  };

  return {
    ...state,
    search,
    searchMore,
  };
};
