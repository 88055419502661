import React from 'react';

import cx from 'classnames';
import getImageRatio from 'utils/getImageRatio';

import { ImageDimensions, ImageCrop } from 'sharedTypes';
import { useInView } from 'react-intersection-observer';

interface Props {
  alt: string;
  isBg?: boolean;
  src: string;
  srcSet?: string;
  sizes?: string;
  className?: string;
  children?: React.ReactNode;
  styleName?: string;
  style?: React.CSSProperties;
  onMount?: () => any;
  onImgLoad?: () => any;
  onError?: (error: Error) => void;
  dimensions?: ImageDimensions;
  crop?: ImageCrop;
  preloadClassName?: string;
  isFullBleed?: boolean;
}

const Img: React.FC<Props> = (props) => {
  const {
    src,
    alt,
    style = {},
    isBg = false,
    className = 'w100',
    children = null,
    srcSet,
    sizes,
    styleName = '',
    crop,
    dimensions,
    preloadClassName,
    isFullBleed = false,
    onMount = () => {},
    onImgLoad = () => {},
    onError = (error: Error) => undefined,
  } = props;

  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const handleLoad = () => {
    onImgLoad();
  };

  const handleError = (error: Error) => {
    if (onError) {
      onError(error);
    } else {
      console.error(`Failed to load image ${src}`, error);
    }
  };

  const classes = cx(className);
  const styles = cx('Img', styleName);

  const bgStyle = {
    backgroundColor: '#9b9b9b',
    backgroundImage: `url(${src})`,
    ...style,
  };

  const fullBleedStyle = isFullBleed
    ? {
        width: '100vw',
        position: 'relative',
        ...style,
      }
    : {};

  const styleNames = cx('Img', {
    'opacity-0': !inView,
  });

  if (!src) return null;

  // Use fullBleedStyle only for non-background images
  const imageStyle = isBg ? bgStyle : fullBleedStyle;

  if (isBg) {
    return (
      <div ref={ref} className={cx(styles, classes)} style={bgStyle}>
        {children}
      </div>
    );
  }

  if (!srcSet && !sizes) {
    return (
      <img
        ref={ref}
        className={cx(styleNames, classes)}
        style={imageStyle}
        src={src}
        alt={alt}
        width="auto"
        height="100%"
        loading={inView ? 'eager' : 'lazy'}
        onLoad={() => handleLoad()}
      />
    );
  }

  return (
    <>
      {dimensions && (
        <div
          className={cx(classes, preloadClassName, {
            'bg-color-gray-lightest-opacity-50': !inView,
            'bg-color-transparent': inView,
          })}
          style={{
            paddingTop: isFullBleed ? 0 : `${getImageRatio(dimensions, crop)}%`,
          }}
        />
      )}
      <picture>
        <img
          ref={ref}
          className={cx(styleNames, classes, {
            'absolute t0 r0 l0 w100': dimensions,
          })}
          style={imageStyle}
          src={src}
          srcSet={srcSet}
          sizes={sizes}
          alt={alt}
          width="auto"
          height="100%"
          onLoad={() => handleLoad()}
          loading={inView ? 'eager' : 'lazy'}
        />
      </picture>
    </>
  );
};

export default Img;
