const HotspotGroq = `{
  _type,
  hotspotText,
  hotspotLink,
  x,
  y,
  useDarkModeColorScheme,
}`;

export default HotspotGroq;
