const Environments = {
  PROD: {
    hostnames: [
      'www.tabletmag.com',
      'tabletmag.com',
      'www.tabletmag.com.s3-website-us-east-1.amazonaws.com',
    ],
  },
  STAGING: {
    hostnames: [
      'tabletmag.vercel.app',
      'staging.tabletmag.com',
      'staging.tabletmag.com.s3-website-us-east-1.amazonaws.com',
    ],
  },
  INTEGRATION: {
    hostnames: [
      'integration.tabletmag.com',
      'integration.tabletmag.com.s3-website-us-east-1.amazonaws.com',
    ],
  },
  LOCAL: {
    hostnames: ['localhost:3000'],
  },
};

export default Environments;
