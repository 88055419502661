import React from 'react';
import NextLink from 'next/link';

interface LinkProps {
  to: string;
  [key: string]: any;
}

export const Link: React.FC<LinkProps> = ({ children, to, ...rest }) => {
  return (
    <NextLink href={to} as={to}>
      <a {...rest}>{children}</a>
    </NextLink>
  );
};
