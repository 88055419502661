import debounce from 'lodash/debounce';

type ResizeCallback = (event: Event) => void;
type RemoveListener = () => void;

export const onWindowResize = (
  cb: ResizeCallback,
  duration = 300
): RemoveListener => {
  const debouncedCallback = debounce(cb, duration);
  window.addEventListener('resize', debouncedCallback);

  const remove = () => window.removeEventListener('resize', debouncedCallback);

  return remove;
};
