import memoize from 'lodash/memoize';

import { Collection } from 'sharedTypes';
import sanitizeCollectionResponse from './sanitizeCollectionResponse';

const defaultArray: unknown[] = [];

export default memoize((response: unknown): Collection[] => {
  const data = Array.isArray(response) ? response : defaultArray;

  return data.map(sanitizeCollectionResponse);
});
