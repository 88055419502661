import { AnalyticsProvider } from 'providers/AnalyticsProvider';
import { TabletLogoLoader } from 'components/TabletLogoLoader';
import { SiteSettingsProvider } from './SiteSettingsProvider';
import { UIProvider } from './UIProvider';
import { SearchProvider } from './SearchProvider';
import { useRouter } from 'next/router';
import { BaseData } from 'sharedTypes';
import { PageProps } from 'utils/next';

export const AllProviders: React.FC<PageProps> = ({
  preview,
  children,
  baseData,
}) => {
  const router = useRouter();
  const isErrorPage = ['/_error', '/404', '/500'].includes(router.route);

  const conditionalRenderProps = {
    preview,
    baseData,
    isErrorPage,
  };

  return (
    <AnalyticsProvider>
      <UIProvider>
        <SearchProvider>
          <ConditionalRender {...conditionalRenderProps}>
            {children}
          </ConditionalRender>
        </SearchProvider>
      </UIProvider>
    </AnalyticsProvider>
  );
};

interface ConditionalRenderProps {
  baseData: BaseData;
  isErrorPage: boolean;
  preview?: boolean;
  children?: React.ReactNode;
}

const ConditionalRender: React.FC<ConditionalRenderProps> = ({
  preview,
  baseData,
  isErrorPage,
  children,
}) => {
  if (!baseData) {
    return <TabletLogoLoader />;
  }

  return (
    <SiteSettingsProvider baseData={baseData} preview={preview}>
      {children}
    </SiteSettingsProvider>
  );
};
