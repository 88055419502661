import ArticleLinkGroq, { ArticleLinkPreviewGroq } from './ArticleLinkGroq';
import WidgetGroq from './WidgetGroq';
import ImageGroq from './ImageGroq';
import { CollectionLayoutLibraryGroq } from './CollectionLayoutLibraryGroq';
import { ColumnGroq } from './ColumnGroq';

const LayoutModuleRepeaterGroq = `'modules': layoutModuleRepeater[] {
  'id': _key,
  'type': _type,
  alignment,
  article1->${ArticleLinkGroq},
  article1TeaserType,
  article2->${ArticleLinkGroq},
  article2TeaserType,
  article3->${ArticleLinkGroq},
  article3TeaserType,
  article4->${ArticleLinkGroq},
  article4TeaserType,
  article5->${ArticleLinkGroq},
  article5TeaserType,
  article6->${ArticleLinkGroq},
  article6TeaserType,
  article7->${ArticleLinkGroq},
  article7TeaserType,
  article8->${ArticleLinkGroq},
  article8TeaserType,
  article9->${ArticleLinkGroq},
  article9TeaserType,
  article10->${ArticleLinkGroq},
  article10TeaserType,
  widget1[0]${WidgetGroq},
  widget2[0]${WidgetGroq},
  'widgets': widgets[]${WidgetGroq},
  article->${ArticleLinkGroq},
  holiday->{
    'id': _key,
    title,
    slug,
    startDate,
    endDate
  },
  holidayHeroColor,
  showImage,
  showIntroText,
  "banner": banner{
    title,
    description,
    "image": image${ImageGroq},
    slug
  },
  useAlternative6Collection,
  collectionsLayoutLibrary->${CollectionLayoutLibraryGroq},
  column->${ColumnGroq},
  "variant": type,
  size,
  text
}`;

export const SectionPreviewLayoutModuleRepeaterGroq = `'modules': layoutModuleRepeater[] {
  'id': _key,
  'type': _type,
  alignment,
  article1->${ArticleLinkPreviewGroq},
  article1TeaserType,
  article2->${ArticleLinkPreviewGroq},
  article2TeaserType,
  article3->${ArticleLinkPreviewGroq},
  article3TeaserType,
  article4->${ArticleLinkGroq},
  article4TeaserType,
  article5->${ArticleLinkGroq},
  article5TeaserType,
  article6->${ArticleLinkGroq},
  article6TeaserType,
  article7->${ArticleLinkGroq},
  article7TeaserType,
  article8->${ArticleLinkGroq},
  article8TeaserType,
  article9->${ArticleLinkGroq},
  article9TeaserType,
  article10->${ArticleLinkGroq},
  article10TeaserType,
  holiday->{
    'id': _key,
    title,
    slug,
    startDate,
    endDate
  },
  useAlternative6Collection,
  collectionsLayoutLibrary->${CollectionLayoutLibraryGroq},
  column->${ColumnGroq},
  holidayHeroColor,
  showImage,
  showIntroText,
  "variant": type,
  size,
  text
}`;

export default LayoutModuleRepeaterGroq;
