import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { Collection } from 'sharedTypes';

export default memoize((data: unknown): Collection => {
  return {
    id: get(data, '_id', ''),
    type: get(data, '_type', 'collection'),
    title: get(data, 'title', ''),
    description: get(data, 'description'),
    image: get(data, 'image'),
    slug: get(data, 'slug', ''),
    featuredArticles: get(data, 'featuredArticles'),
  };
});
