import ArticleLinkGroq from './ArticleLinkGroq';
import ImageGroq from './ImageGroq';

/* TO-DO: [DEPRECATED] Once Holidays v2 is launched "featuredArticle" can be removed */
const HolidayGroq = `
  'id': _id,
  'type': _type, 
  "holidayBanner": holidayBanner${ImageGroq},
  "heroIllustration": heroIllustration${ImageGroq},
  "heroIllustrationMobile": heroIllustrationMobile${ImageGroq},
  'tag': tag->{ 'id': _id, title, slug },
  "featuredArticles": featuredArticles[]->${ArticleLinkGroq}, 
  "aboutHolidayArticle": aboutHolidayArticle->${ArticleLinkGroq}, 
  "contentTabs": contentTabs[]{
    _type,
    _key,
    'tag': tag->{ 'id': _id, title, slug },
    'articles': articles[]->${ArticleLinkGroq},
    ...
  },
  
  briefDescription,
  detailedDescription,

  "stories1": stories1{
    storiesTitle,	
    'article1': article1->${ArticleLinkGroq},	
    article1TeaserType,	
    'article2': article2->${ArticleLinkGroq},	
    article2TeaserType,	
    'article3': article3->${ArticleLinkGroq},	
    article3TeaserType,
    'tag': tag->{ 'id': _id, title, slug }
  },
  "stories2": stories2{
    storiesTitle,	
    'article1': article1->${ArticleLinkGroq},	
    article1TeaserType,	
    'article2': article2->${ArticleLinkGroq},	
    article2TeaserType,	
    'article3': article3->${ArticleLinkGroq},	
    article3TeaserType,
    'tag': tag->{ 'id': _id, title, slug }
  },
  "stories3": stories3{
    storiesTitle,	
    'article1': article1->${ArticleLinkGroq},	
    article1TeaserType,	
    'article2': article2->${ArticleLinkGroq},	
    article2TeaserType,	
    'article3': article3->${ArticleLinkGroq},	
    article3TeaserType,
    'tag': tag->{ 'id': _id, title, slug }
  },
  "featuredArticle": featuredArticle->${ArticleLinkGroq},
  ...
`;

export const HolidayLinkGroq = `{
  'id': _id,
  'type': _type,
  startDate,
  endDate,
  slug,
  title,
  briefDescription,
  detailedDescription,
  sideNavOrder,
  hideInSideNav
}
`;

export const HolidayBannerGroq = `{
  'id': _id,
  'type': _type,
  slug,
  "holidayBanner": holidayBanner${ImageGroq},
}`;

export default HolidayGroq;
