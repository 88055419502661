import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { ColumnistsPage } from 'sharedTypes';

export default memoize((data: unknown): ColumnistsPage => {
  return {
    type: get(data, '_type', 'columnistsPage'),
    id: get(data, '_id', ''),
    description: get(data, 'description'),
  };
});
