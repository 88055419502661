import ImageGroq from './ImageGroq';

export const ColumnGroq = `{
  _id,
  _type,
  "image":image${ImageGroq},
  slug,
  title,
  description,
  createdAt,
}`;
