import React from 'react';
import cx from 'classnames';
import { Button } from '../components/base';
import SocialMediaLinks from '../components/SocialMediaLinks';
import { Theme } from '../types';
import Language from '../constants/Language';
import { RouteMap } from '../constants/RouteMap';
import { useUI } from '../providers/UIProvider';
import { useIsFiction } from '../../src/hooks/useFiction';

interface MenuProps {
  theme: Theme;
}

export const TermsOfService: React.FC<MenuProps> = () => {
  const { theme } = useUI();
  return (
    <div
      className={cx(
        'Footer__section flex flex-row items-center justify-center sm:block md:flex',
        {
          'Footer__section--black': theme === Theme.Black,
        }
      )}
    >
      <Button
        ariaLabel={Language.t('Footer.termsOfServiceAriaLabel')}
        className="p0 bg-color-transparent termsBtn1"
        to={RouteMap.TERMS_OF_SERVICE.path}
      >
        <span
          className={cx(
            'Footer__text linked-border-bottom-black graebenbach text-section-body-xs font-400',
            {
              'color-black':
                theme === Theme.Default ||
                theme === Theme.Beige ||
                theme === Theme.CollectionWhite ||
                theme === Theme.Sand,
              'color-gray-darker': theme === Theme.Black,
            }
          )}
        >
          {Language.t('Footer.termsOfService')}
        </span>
      </Button>
      <Button
        ariaLabel={Language.t('Footer.privacyPolicyAriaLabel')}
        className="p0 bg-color-transparent"
        to={RouteMap.PRIVACY_POLICY.path}
      >
        <span
          className={cx(
            'Footer__text linked-border-bottom-black graebenbach text-section-body-xs font-400 mr_5',
            {
              'color-black':
                theme === Theme.Default ||
                theme === Theme.Beige ||
                theme === Theme.CollectionWhite ||
                theme === Theme.Sand,
              'color-gray-darker': theme === Theme.Black,
            }
          )}
        >
          {Language.t('Footer.privacyPolicy')}
        </span>
      </Button>
    </div>
  );
};

export const SubscribeNewsletter: React.FC<MenuProps> = () => {
  const { theme } = useUI();
  return (
    <div
      className={cx(
        'Footer__section Footer__section--subscribe flex items-center justify-center sm:block md:flex',
        {
          'Footer__section--black': theme === Theme.Black,
        }
      )}
    >
      <Button
        ariaLabel={Language.t('Footer.subscribeNewsletterAriaLabel')}
        className="p0 bg-color-transparent text-center sm:text-left"
        to={RouteMap.NEWSLETTER.path}
        wrap={true}
      >
        <span
          className={cx(
            'Footer__text Footer__subscribe-newsletter-text linked-border-bottom-black graebenbach text-section-body-xs font-400',
            {
              'color-black':
                theme === Theme.Default ||
                theme === Theme.Beige ||
                theme === Theme.CollectionWhite ||
                theme === Theme.Sand,
              'color-gray-darker': theme === Theme.Black,
            }
          )}
        >
          {Language.t('Footer.subscribeNewsletter')}
        </span>
      </Button>
    </div>
  );
};

export const DonateToTablet: React.FC<MenuProps> = () => {
  const { theme } = useUI();
  return (
    <div
      className={cx(
        'Footer__section flex items-center justify-center sm:block md:flex',
        {
          'Footer__section--black': theme === Theme.Black,
        }
      )}
    >
      <Button
        ariaLabel={Language.t('Footer.donateToTabletAriaLabel')}
        className="p0 bg-color-transparent text-center sm:text-left"
        to={RouteMap.DONATE.path}
      >
        <span
          className={cx(
            'Footer__text linked-border-bottom-black graebenbach text-section-body-xs font-400 vertical-align-middle',
            {
              'color-black':
                theme === Theme.Default ||
                theme === Theme.Beige ||
                theme === Theme.CollectionWhite ||
                theme === Theme.Sand,
              'color-gray-darker': theme === Theme.Black,
            }
          )}
        >
          {Language.t('Footer.donateToTablet')}
        </span>
      </Button>
    </div>
  );
};

export const SocialMedia: React.FC<MenuProps> = () => {
  const { theme } = useUI();
  const isFiction = useIsFiction();

  return (
    <div
      className={cx(
        'Footer__section flex items-center md:flex justify-center sm:justify-end',
        {
          'Footer__section--black': theme === Theme.Black,
        }
      )}
    >
      <span
        className={cx(
          'Footer__text mr1 graebenbach text-section-body-xs font-400',
          {
            'color-black':
              theme === Theme.Default ||
              theme === Theme.Beige ||
              theme === Theme.Sand,
            'color-gray-darker': theme === Theme.Black,
          }
        )}
      >
        {Language.t('Footer.followUs')}
      </span>
      <SocialMediaLinks
        className="mt_375 sm:mt0 md:mt_375 flex items-center"
        color={
          isFiction
            ? 'fiction-blue'
            : theme === Theme.Black
            ? 'gray-darker'
            : 'black'
        }
        facebookIconClassName="Footer__facebook-icon"
        instagramIconClassName="Footer__instagram-icon"
        twitterIconClassName="Footer__twitter-icon"
        iconSpacingClassName="mr_75"
      />
    </div>
  );
};

interface FooterProps {
  removeBorderTop?: boolean;
  removeMaxWidth?: boolean;
  isCollectionsPath?: boolean;
}

const Footer: React.FC<FooterProps> = ({
  removeBorderTop,
  removeMaxWidth,
  isCollectionsPath,
}) => {
  const { theme } = useUI();

  return (
    <footer
      className={cx(
        'Footer inner-content-max-width content-padding-x mxauto vw100 no-display-in-print',
        {
          'bg-color-beige': theme === Theme.Beige,
          'bg-color-off-white': theme === Theme.Sand,
          'Footer--no-border-top': removeBorderTop,
          CollectionsGridItems__Footer__container: isCollectionsPath,
        }
      )}
    >
      <div
        className={cx('Footer__container flex flex-col sm:flex-row', {
          'Footer__container--no-max-width': removeMaxWidth,
        })}
      >
        <div className={cx('Footer__links flex flex-col sm:flex-row')}>
          <TermsOfService theme={theme} />
          <SubscribeNewsletter theme={theme} />
          <DonateToTablet theme={theme} />
        </div>
        <SocialMedia theme={theme} />
      </div>
    </footer>
  );
};

export default Footer;
