import React, { Component } from 'react';
import get from 'lodash/get';

import cx from 'classnames';

interface Props {
  className: string;
  sideNavIsOpen: boolean;
  color?: string;
}

const defaultProps = {
  className: '',
};

interface State {
  progressBar: number;
}

class ProgressBar extends Component<Props> {
  static defaultProps = defaultProps;

  state: State = {
    progressBar: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.getScrollPercentage);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getScrollPercentage);
  }

  getScrollPercentage = () => {
    const viewHeight = get(window, 'innerHeight', 0);
    const windowHeight = get(document, 'body.scrollHeight', 0);
    const totalDistance = windowHeight - viewHeight;

    const scrollTop =
      get(window, 'pageYOffset', 0) ||
      get(document, 'documentElement.scrollTop', 0) ||
      get(document, 'body.parentNode.scrollTop', 0) ||
      get(document, 'body.scrollTop', 0);

    const percentage = (scrollTop / totalDistance) * 100;

    this.setState({ progressBar: percentage });
  };

  render() {
    const { className, sideNavIsOpen, color } = this.props;

    const classes = cx(
      `ProgressBar fixed l0 r0 t0 z-overlay no-display-in-print`,
      className
    );

    return (
      <div
        className={cx(
          {
            'ProgressBar--side-nav-is-active': sideNavIsOpen,
          },
          classes
        )}
        style={{
          width: `${this.state.progressBar}%`,
          backgroundColor: color ? color : '#dd3418',
        }}
      />
    );
  }
}

export default ProgressBar;
