import get from 'lodash/get';

import { Section } from 'sharedTypes';

const sanitizeSectionResponse = (data: unknown): Section => {
  return {
    _id: get(data, '_id', ''),
    _type: get(data, '_type', 'section'),
    title: get(data, 'title', ''),
    images: get(data, 'images', {
      dark: { id: '', src: '' },
      light: { id: '', src: '' },
      blue: { id: '', src: '' },
    }),
    slug: get(data, 'slug', ''),
    modules: get(data, 'modules', []),
    sideNavOrder: get(data, 'sideNavOrder', 100),
    hideInSideNav: get(data, 'hideInSideNav', false),
    seo: {
      title: get(data, 'seo.title', ''),
      description: get(data, 'seo.description', ''),
      image: {
        src: get(data, 'seo.image.src', ''),
        caption: get(data, 'seo.image.caption', ''),
        credit: get(data, 'seo.image.credit', ''),
        id: get(data, 'seo.image.id', ''),
        alt: get(data, 'seo.image.alt', ''),
        metadata: get(data, 'seo.image.metadata'),
      },
    },
  };
};

export default sanitizeSectionResponse;
