import Environments from 'constants/Environments';

const environmentIsIntegration = (
  simulateIntegrationEnvironment: boolean = false
): boolean => {
  // NEXT_TODO: Check this with the SITE_ENV variable
  if (typeof window === 'undefined') return false;
  if (simulateIntegrationEnvironment) return true;

  return Environments.INTEGRATION.hostnames.includes(window.location.host);
};

export default environmentIsIntegration;
