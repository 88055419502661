import { useState, useCallback, useEffect } from 'react';
import { Paginated } from 'types';

type UnParamedPaginationRequest<T> = (
  prevPage?: Paginated<T>
) => Promise<Paginated<T>>;
type ParameterizedPaginationRequest<T> = (
  param: any,
  prevPage?: Paginated<T>
) => Promise<Paginated<T>>;

type PaginationRequest<T = any> =
  | UnParamedPaginationRequest<T>
  | ParameterizedPaginationRequest<T>;
/**
 * Given a valid PaginationRequest,
 * returns the type of the parameters for that method
 */
type ExtractPaginationParams<R> = R extends ParameterizedPaginationRequest<any>
  ? Parameters<R>[0]
  : R extends UnParamedPaginationRequest<any>
  ? null
  : never;
// extends PaginationArgs<any, infer T>
//   ? T
//   : never
// : never;

/**
 * Given a valid PaginationRequest,
 * returns the type of its paginated data
 */
type ExtractPaginationType<R> = R extends PaginationRequest
  ? ReturnType<R> extends Promise<Paginated<infer T>>
    ? T
    : never
  : never;

interface ReturnValue<T> {
  data: Paginated<T>;
  fetchMore: () => Promise<void>;
  loading: boolean;
}

export const createEmptyPagination = <T>(): Paginated<T> => ({
  items: [],
  totalItems: undefined,
  hasNextPage: false,
});

/**
 * @param {callback} callback - A function that returns a paginated response
 * @param {params} params - the params for that function
 * @param {initialData} [initialData] - a previously fetched page
 *
 * @returns {ReturnValue} - an object consisting of:
 *  data: the merged pagination data
 *  loading: a boolean for the loading state
 *  fetchMore: a function that will fetch more data, then update the state
 *
 * If initialData is not included, an initial fetch will be made on mount.
 * Otherwise, it does nothing until fetchMore is invoked.
 */
export const usePaginatedRequest = <
  R extends PaginationRequest,
  P extends ExtractPaginationParams<R>,
  T extends ExtractPaginationType<R>
>(
  callback: PaginationRequest<T>,
  params: P,
  initialData?: Paginated<T>
): ReturnValue<T> => {
  const [data, setData] = useState<Paginated<T>>(
    initialData || createEmptyPagination<T>()
  );
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Call the pagination method with the same params,
   * and include the latest paginated data
   * so it can fetch the next page
   */
  const fetchMore = useCallback(async () => {
    setLoading(true);
    const nextPage = await callback(params, data);
    setData(nextPage);
    setLoading(false);
  }, [callback, params, data]);

  /**
   * If we did not pass in any initial data, make an initial request.
   * Otherwise, skip this and wait for fetchMore to be called later.
   */
  useEffect(() => {
    if (!initialData) {
      fetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, fetchMore, loading };
};
