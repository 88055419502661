import get from 'lodash/get';
import memoize from 'lodash/memoize';
import { RecipesPageResponse } from 'sharedTypes';

export const sanitizeRecipesPageResponse = memoize(
  (data: RecipesPageResponse): RecipesPageResponse => {
    const {
      recipesPage,
      allRecipes,
      // seasonal,
      byHoliday,
      foodSection,
    } = data;

    const sanitized = {
      /* Main recipes page data */
      recipesPage: {
        seo: {
          title: get(recipesPage, 'seo.title'),
          description: get(recipesPage, 'seo.description'),
          image: {
            src: get(recipesPage, 'seo.image.src'),
            caption: get(recipesPage, 'seo.image.caption'),
            credit: get(recipesPage, 'seo.image.credit'),
            id: get(recipesPage, 'seo.image.id'),
            alt: get(recipesPage, 'seo.image.alt'),
            metadata: get(recipesPage, 'seo.image.metadata'),
            crop: get(recipesPage, 'seo.image.crop'),
          },
        },
        recipeIllustration: get(recipesPage, 'recipeIllustration'),
        illustrationIsTransparent: get(
          recipesPage,
          'illustrationIsTransparent'
        ),
        editorsPicks: get(recipesPage, 'editorsPicks'),
        recipeTab: get(recipesPage, 'recipeTab'),
        recipeTabAdditional: get(recipesPage, 'recipeTabAdditional'),
      },
      /* All recipes */
      allRecipes,
      // /* By Season */
      // seasonal,
      /* By Holiday */
      byHoliday,
      foodSection,
    };
    return sanitized;
  }
);
