import ImageGroq from './ImageGroq';

export const recipeTeaserGroq = `
    _id,
    _key,
    _type,
    title,
    slug,
    "featuredImage": featuredImage${ImageGroq},
    "heroImage": heroImage${ImageGroq},
    categories,
    "releaseDate": coalesce(releaseDate, _createdAt),
    "createdAt": _createdAt,
    authors[]->{
      firstName,
      lastName,
      slug,
      legacySlug
    },
    recipeAuthors,
    "meta": {
      tags[]->{ title, slug },
      brief,
      "releaseDate": releaseDate,
      "createdAt": _createdAt,
      "updatedAt": _updatedAt,
   },
`;

export const recipesPageQuery = `
  *[_id == $id]{
    "recipeIllustration": recipeIllustration${ImageGroq},
    recipeIsTransparent,
    editorsPicks {
      title,
      recipes[]->[
        !defined(releaseDate) || releaseDate <= $currentDate
      ]{
        ${recipeTeaserGroq}
      },
    },
    recipeTab {
      title,
      recipes[]->[
        !defined(releaseDate) || releaseDate <= $currentDate
      ]{
        ${recipeTeaserGroq}
      },
    },
    recipeTabAdditional {
      title,
      recipes[]->[
        !defined(releaseDate) || releaseDate <= $currentDate
      ]{
        ${recipeTeaserGroq}
      },
    },
    "seo": {
      "title": seoTitle,
      "description": seoDescription,
      "image": seoImage${ImageGroq}
    },
    ...
  }[0]
`;

export const recipesBySeasonQuery = `
  {
    "spring": *[
      _type == "recipeArticle" && season.spring == true
      && (!defined(releaseDate) || releaseDate <= $currentDate)
    ]{
      ${recipeTeaserGroq}
    },
    "summer": *[
      _type == "recipeArticle" && season.summer == true
      && (!defined(releaseDate) || releaseDate <= $currentDate)
    ]{
      ${recipeTeaserGroq}
    },
    "fall": *[
      _type == "recipeArticle" && season.fall == true
      && (!defined(releaseDate) || releaseDate <= $currentDate)
    ]{
      ${recipeTeaserGroq}
    },
    "winter": *[
      _type == "recipeArticle" && season.winter == true
      && (!defined(releaseDate) || releaseDate <= $currentDate)
    ]{
      ${recipeTeaserGroq}
    },
  }
`;

export const recipesByHolidayQuery = `
  *[_type == 'holiday'] | order(startDate asc) {
    id,
    title,
    slug,
    startDate,
    endDate,
    briefDescription,
    detailedDescription,
    relatedRecipes[]->[
      !defined(releaseDate) || releaseDate <= $currentDate
    ]{
      ${recipeTeaserGroq}
    }
 }

`;

export const allRecipesQuery = `*[
    _type == "recipeArticle"
    && (!defined(releaseDate)
    || releaseDate <= $currentDate)
  ] | order(coalesce(releaseDate, _createdAt) desc) {
    ${recipeTeaserGroq}
}[$start...$end]

`;
