import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { RecipeArticle, RecipeCategories } from 'sharedTypes';

export default memoize((data: unknown): RecipeArticle => {
  return {
    _type: get(data, '_type', 'recipeArticle'),
    id: get(data, 'id', ''),
    title: get(data, 'title', ''),
    slug: `/recipes/${get(data, 'slug', '')}`,
    seo: {
      title: get(data, 'seo.title', ''),
      description: get(data, 'seo.description', ''),
      image: {
        src: get(data, 'seo.image.src', ''),
        caption: get(data, 'seo.image.caption'),
        credit: get(data, 'seo.image.credit'),
        id: get(data, 'seo.image.id', ''),
        alt: get(data, 'seo.image.alt'),
        metadata: get(data, 'seo.image.metadata'),
        crop: get(data, 'seo.image.crop'),
      },
    },
    meta: {
      date:
        get(data, 'meta.releaseDate', '') || get(data, 'meta.createdAt', ''),
      releaseDate: get(data, 'meta.releaseDate', ''),
      authors: get(data, 'meta.authors', []),
      recipeAuthors: get(data, 'meta.recipeAuthors', []),
      tags: get(data, 'meta.tags', []),
      brief: get(data, 'meta.brief', ''),
      createdAt: get(data, 'meta.createdAt', ''),
      updatedAt: get(data, 'meta.updatedAt', ''),
      categories: get(data, 'meta.categories', {} as RecipeCategories),
    },
    content: {
      intro: get(data, 'intro', []),
      heroImage: get(data, 'heroImage'),
      body: get(data, 'body', []),
      featuredImage: get(data, 'featuredImage'),
      recipeParts: get(data, 'recipeParts'),
      recipeYield: get(data, 'recipeYield'),
      recipeTime: get(data, 'recipeTime'),
      note: get(data, 'note'),
      relatedArticles: get(data, 'relatedArticles', []),
      relatedRecipes: get(data, 'relatedRecipes', []),
      relatedHolidays: get(data, 'relatedHolidays', []),
    },
  };
});
