import { CollectionGroq } from './CollectionGroq';

export const CollectionsPageGroq = `{
  _id,
  _type,
  description,
  collection1->${CollectionGroq},
  collection2->${CollectionGroq},
  collection3->${CollectionGroq},
  collection4->${CollectionGroq},
  collection5->${CollectionGroq},
  collection6->${CollectionGroq},
  collection7->${CollectionGroq},
  collection8->${CollectionGroq},
  collection9->${CollectionGroq},
  collection10->${CollectionGroq},
  collection11->${CollectionGroq},
  collection12->${CollectionGroq},
}`;
