import get from 'lodash/get';

import { FeatureArticlePage, SectionLink } from 'sharedTypes';

const sanitizeFeatureArticlePageResponse = (data: any): FeatureArticlePage => {
  return {
    _type: get(data, '_type', 'featureArticlePage'),
    title: get(data, 'title', ''),
    hideHeadline: get(data, 'hideHeadline'),
    dek: get(data, 'dek', ''),
    slug: get(data, 'slug', ''),
    authors: get(data, 'authors', []),
    section: get(data, 'section', {} as SectionLink),
    releaseDate: get(data, 'releaseDate', ''),
    heroImage: {
      src: get(data, 'heroImage.src', ''),
      rteCredit: get(data, 'heroImage.rteCredit', []),
      caption: get(data, 'heroImage.caption', ''),
      credit: get(data, 'heroImage.credit', ''),
      id: get(data, 'heroImage.id', ''),
      alt: get(data, 'heroImage.alt', ''),
      metadata: get(data, 'heroImage.metadata'),
      crop: get(data, 'heroImage.crop'),
    },
    mobileHeroImage: {
      src: get(data, 'mobileHeroImage.src', ''),
      rteCredit: get(data, 'mobileHeroImage.rteCredit', []),
      caption: get(data, 'mobileHeroImage.caption', ''),
      credit: get(data, 'mobileHeroImage.credit', ''),
      id: get(data, 'mobileHeroImage.id', ''),
      alt: get(data, 'mobileHeroImage.alt', ''),
      metadata: get(data, 'mobileHeroImage.metadata'),
      crop: get(data, 'mobileHeroImage.crop'),
    },
    useArticleHeaderHero: get(data, 'useArticleHeaderHero', false),
    isHighDefImage: get(data, 'isHighDefImage', false),
    featuredContent: {
      featuredImage: get(data, 'featuredContent.featuredImage'),
      featuredQuote: get(data, 'featuredContent.featuredQuote', null),
      featuredFact: get(data, 'featuredContent.featuredFact', null),
    },
    content: {
      body: get(data, 'content.body', []),
    },
    seo: {
      title: get(data, 'seo.title', ''),
      description: get(data, 'seo.description', ''),
      image: {
        src: get(data, 'seo.image.src', ''),
        caption: get(data, 'seo.image.caption', ''),
        credit: get(data, 'seo.image.credit', ''),
        id: get(data, 'seo.image.id', ''),
        alt: get(data, 'seo.image.alt', ''),
        metadata: get(data, 'seo.image.metadata'),
      },
    },
    backgroundColor: {
      _type: get(data, 'backgroundColor._type', 'color'),
      hex: get(data, 'backgroundColor.hex', ''),
    },
    progressBarColor: {
      _type: get(data, 'progressBarColor._type', 'color'),
      hex: get(data, 'progressBarColor.hex', ''),
    },
    bodyTextColor: {
      _type: get(data, 'bodyTextColor._type', 'color'),
      hex: get(data, 'bodyTextColor.hex', ''),
    },
    footerLogoTheme: get(data, 'footerLogoTheme', 'dark'),
  };
};

export default sanitizeFeatureArticlePageResponse;
