import { PaginationArgs, Paginated } from 'types';

export const paginate = <T>(
  items: T[],
  totalItems: number = 0,
): Paginated<T> => {
  return {
    items,
    totalItems,
    hasNextPage: items.length < totalItems,
  };
};

export const getParamsFromPreviousPage = <T>(
  prevData?: Paginated<T>,
): PaginationArgs<T> => {
  if (!prevData) {
    return { after: 0, prevTotal: undefined, prevItems: [] };
  }
  return {
    after: prevData.items.length,
    prevTotal: prevData.totalItems,
    prevItems: prevData.items,
  };
};

export const mergePaginationResults = <T>(
  items: T[],
  total?: number,
  prevData?: Paginated<T>,
): Paginated<T> => {
  const { items: prevItems = [], totalItems: prevTotal } = prevData || {};
  return paginate([...prevItems, ...items], total || prevTotal);
};
