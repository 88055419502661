const AuthorLinkGroq = `
{
  _id,
  _type,
  firstName,
  lastName,
  slug,
  legacySlug,
  bio,
}
`;

export default AuthorLinkGroq;
