import React from 'react';

import { Colors, HexColors } from 'constants/Colors';

interface Props {
  color: Colors;
  className?: string;
}

const FacebookIcon: React.FC<Props> = ({ color, className = '' }) => {
  return (
    <svg
      className={className}
      width="9px"
      height="18px"
      viewBox="0 0 9 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>Facebook Logo.</desc>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-54.000000, -858.000000)">
          <path
            d="M61.3568115,860.988281 C60.0681818,860.988281 59.8193248,861.621094 59.8193248,862.545696 L59.8193248,864.75 L62.7272727,864.75 L62.3284024,867.949219 L59.8193248,867.949219 L59.8193248,876 L56.6147794,876 L56.6147794,867.949219 L54,867.949219 L54,864.75 L56.6147794,864.75 L56.6147794,862.22929 C56.6147794,859.490639 58.2374933,858 60.6068115,858 L61.0819758,858.004981 C62.0064854,858.024107 62.7574732,858.093413 63,858.126549 L63,860.988281 L61.3568115,860.988281 Z"
            fill={HexColors[color]}
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default FacebookIcon;
