import CreateClient, { SanityClient as SanityClientType } from '@sanity/client';

export type SanityDataset = 'staging' | 'production';

type QueryParams = Record<string, any>;

export const dataset =
  process.env.NEXT_PUBLIC_ENV === 'production' ? 'production' : 'staging';

// TODO: Move this to a common config
if (process.env.NEXT_PUBLIC_ENV === 'production' && dataset !== 'production') {
  throw new Error('Oops! Your dataset must be set to production');
}

const projectId =
  process.env.REACT_APP_SANITY_PROJECT_ID ||
  process.env.NEXT_PUBLIC_SANITY_PROJECT_ID;

if (!projectId) {
  throw new Error('You must include a SANITY_PROJECT_ID');
}

const isSpiritFish =
  // @ts-ignore
  typeof window !== 'undefined' && Boolean(window.SPIRIT_FISH);

// Don't ever use the CDN if we're rendering in Spirit Fish
const useCdn = isSpiritFish ? false : true;

export const SanityClient = CreateClient({
  projectId,
  dataset,
  token: '',
  useProjectHostname: true,
  useCdn,
  apiVersion: '2020-03-01',
});

export const SanityUpdateClient = CreateClient({
  projectId,
  dataset,
  token: process.env.NEXT_PUBLIC_SANITY_UPDATE_TOKEN,
  useProjectHostname: true,
  useCdn,
  apiVersion: '2020-03-01',
});

const SanityPreviewClient = CreateClient({
  projectId,
  dataset,
  token:
    process.env.REACT_APP_SANITY_PREVIEW_TOKEN ||
    process.env.NEXT_PUBLIC_SANITY_PREVIEW_TOKEN,
  useProjectHostname: true,
  useCdn: false,
  apiVersion: '2020-03-01',
});

class Client {
  private cache: { [key: string]: any };
  private SanityClient: SanityClientType;

  constructor(client: SanityClientType) {
    this.SanityClient = client;
    this.cache = {};
  }

  async getDocuments(ids: string[]) {
    // TODO Cache me
    return this.SanityClient.getDocuments(ids);
  }

  async getDocument(id: string) {
    // TODO Cache me
    return this.SanityClient.getDocument(id);
  }

  async fetch<ResponseType = any>(
    query: string,
    params?: QueryParams
  ): Promise<ResponseType | null> {
    return this.SanityClient.fetch(query, params || {});
  }
}

export const PreviewClient = new Client(SanityPreviewClient);
// eslint-disable-next-line import/no-anonymous-default-export
export default new Client(SanityClient);
