import Environments from 'constants/Environments';

const environmentIsLocal = (
  simulateLocalEnvironment: boolean = false
): boolean => {
  if (simulateLocalEnvironment) return true;

  // NEXT_TODO: Check this with the SITE_ENV variable
  if (typeof window === 'undefined') return false;

  return Environments.LOCAL.hostnames.includes(window.location.host);
};

export default environmentIsLocal;
