import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { FrontPage } from '../sharedTypes';

export default memoize((data: unknown): FrontPage => {
  return {
    _type: get(data, '_type', 'frontPage'),
    modules: get(data, 'modules', []),
    holiday: get(data, 'holiday'),
    featuredImage: {
      src: get(data, 'featuredImage.src', ''),
      id: get(data, 'featuredImage.id', ''),
      metadata: get(data, 'featuredImage.metadata'),
    },
    featuredLink: get(data, 'featuredLink'),
    showTagline: get(data, 'showTagline'),
    taglineText: get(data, 'taglineText'),
    showAppButton: get(data, 'showAppButton'),
  };
});
