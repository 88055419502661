import LayoutModuleRepeaterGroq, {
  SectionPreviewLayoutModuleRepeaterGroq,
} from './LayoutModuleRepeaterGroq';
import ImageGroq from './ImageGroq';
import ArticleLinkGroq from './ArticleLinkGroq';

const SectionGroq = `{
  _id,
  _type,
  slug,
  title,
  "images": {
    "dark": darkLogo${ImageGroq},
    "light": lightLogo${ImageGroq},
    "blue": blueLogo${ImageGroq}
  },
  sideNavOrder,
  hideInSideNav,
  ${LayoutModuleRepeaterGroq},
  "contentTabs": contentTabs[]{
    'tag': tag->{ 'id': _id, title, slug },
    'articles': articles[]->${ArticleLinkGroq},
    ...
  },

  "seo": {
    "title": seoTitle,
    "description": seoDescription,
    "image": seoImage${ImageGroq}
  },
}`;

export const SectionsNavGroq = `{
  _id,
  _type,
  slug,
  title,
  "images": {
    "dark": darkLogo${ImageGroq},
    "light": lightLogo${ImageGroq},
    "blue": blueLogo${ImageGroq}
  },
  sideNavOrder,
  hideInSideNav,
  ${SectionPreviewLayoutModuleRepeaterGroq}[0..2],
}`;

export default SectionGroq;
