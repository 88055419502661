import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { EncyclopediaTerm } from 'sharedTypes';

const defaultData: unknown[] = [];

export default memoize((response: unknown): EncyclopediaTerm[] => {
  const data = Array.isArray(response) ? response : defaultData;

  return data.map((encyclopediaTerm: unknown) => ({
    term: get(encyclopediaTerm, 'term', ''),
    pronunciation: get(encyclopediaTerm, 'pronunciation', ''),
    typeOfWord: get(encyclopediaTerm, 'typeOfWord', ''),
    definition: get(encyclopediaTerm, 'definition', ''),
    link: get(encyclopediaTerm, 'link', ''),
  }));
});
