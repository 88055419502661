import get from 'lodash/get';

import { Encyclopedia } from 'sharedTypes';

const sanitizeEncyclopediaResponse = (data: unknown): Encyclopedia => {
  return {
    title: get(data, 'title', ''),
    description: get(data, 'description', []),
    stickerLink: get(data, 'stickerLink', ''),
    stickerImage: get(data, 'stickerImage', { id: '', src: '' }),
  };
};

export default sanitizeEncyclopediaResponse;
