import ArticleLinkGroq from './ArticleLinkGroq';
import ImageGroq from './ImageGroq';

export const CollectionGroq = `{
  _id,
  _type,
  "image": image${ImageGroq},
  slug,
  description,
  title,
  tag,
  "featuredArticles": featuredArticles[]->${ArticleLinkGroq}, 
}`;
