import get from 'lodash/get';
import memoize from 'lodash/memoize';

import sanitizeArticleLinks from 'sanitizers/sanitizeArticleLinks';
import articlesByDates from 'sanitizers/articlesByDate';

import { Author } from 'sharedTypes';

const defaultData: unknown[] = [];

export default memoize((data: unknown): Author => {
  return {
    _id: get(data, '_id', ''),
    _type: get(data, '_type', 'author'),
    firstName: get(data, 'firstName', ''),
    lastName: get(data, 'lastName', ''),
    slug: get(data, 'slug', ''),
    legacySlug: get(data, 'legacySlug'),
    bio: get(data, 'bio', []),
    articles: articlesByDates(
      sanitizeArticleLinks(get(data, 'articles', defaultData))
    ),
  };
});
