import { useEffect, useState } from 'react';

// helps reduces cases of Content Layout Shift
// by a simple check before hydration
const eagerExternalLinkCheckHuerestic = (link: string) => link.includes('http');

const useCheckExternalLink = (link: string) => {
  const initialCheck = eagerExternalLinkCheckHuerestic(link);
  const [isExternal, setIsExternal] = useState(initialCheck);

  useEffect(() => {
    if (typeof window === 'undefined') {
      setIsExternal(false);
    }

    const element = window.document.createElement('a');
    element.href = link;

    setIsExternal(window.location.host !== element.host);
  }, [link]);

  return isExternal;
};

export default useCheckExternalLink;
