import {
  useContext,
  ComponentType,
  FC,
  ReactNode,
  useEffect,
  createContext,
} from 'react';
import { Theme } from 'types';
import { useRouter } from 'next/router';
import { useUiState } from './state';

export type UIContextValue = ReturnType<typeof useUiState>;

const UIContext = createContext<UIContextValue | undefined>(undefined);

export const UIConsumer = UIContext.Consumer;

export const useUI = () => {
  const ctx = useContext(UIContext);
  if (!ctx) throw new Error('useUIContext must be used within a UIProvider');
  return ctx;
};

export const withUI =
  <P extends object>(
    Component: ComponentType<P>
  ): FC<Omit<P, keyof UIContextValue>> =>
  /* eslint-disable react/display-name */
  (props) =>
    (
      <UIConsumer>
        {(value) => <Component {...(props as P)} {...value} />}
      </UIConsumer>
    );

// Theme Colors:
// most are just default
// podcasts are black.
// beige:
//   "/sections/holidays/:slug": "beige",
//   '/recipes': "beige",
//   "/sections/:slug": "beige",
//   '/section/:slug': "beige",
// not beige in sections:
//   '/sections/:section/:slug': "default"

const getThemeByPath = (path: string): Theme => {
  if (/^\/podcasts/.test(path)) return Theme.Black;
  if (/^\/collections/.test(path)) return Theme.CollectionWhite;
  if (/^\/columnists/.test(path)) return Theme.CollectionWhite;
  if (
    /^\/recipes$/.test(path) ||
    /^\/recipes(\/by-holiday)/.test(path) ||
    (/^\/section/.test(path) &&
      !/^\/sections(\/[a-zA-Z0-9\-\_]+)(\/[a-zA-Z0-9\-\_]+)/.test(path))
  ) {
    return Theme.Beige;
  }

  return Theme.Default;
};

interface UIProps {
  children: ReactNode;
}

export const UIProvider = ({ children }: UIProps) => {
  const { asPath } = useRouter();
  const uiStateAndActions = useUiState();

  const { theme, setTheme } = uiStateAndActions;
  /**
   * Update the theme based on the current route
   */
  useEffect(() => {
    const newTheme = getThemeByPath(asPath);
    if (theme !== newTheme) setTheme(newTheme);
  }, [asPath, theme, setTheme]);

  const value = uiStateAndActions;
  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};
