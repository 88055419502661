import get from 'lodash/get';
import memoize from 'lodash/memoize';
import sanitizePodcastResponse from './sanitizePodcastResponse';
import { PodcastsPage } from 'sharedTypes';

const sanitizePodcastsPageResponse = memoize(
  (data: PodcastsPage): PodcastsPage => {
    return {
      _type: get(data, '_type', 'podcastsPage'),
      podcasts: get(data, 'podcasts', []).map(sanitizePodcastResponse),
      seo: {
        title: get(data, 'seo.title', ''),
        description: get(data, 'seo.description', ''),
        image: {
          src: get(data, 'seo.image.src', ''),
          caption: get(data, 'seo.image.caption', ''),
          credit: get(data, 'seo.image.credit', ''),
          id: get(data, 'seo.image.id'),
          alt: get(data, 'seo.image.alt', ''),
          metadata: get(data, 'seo.image.metadata'),
        },
      },
    };
  }
);

export default sanitizePodcastsPageResponse;
