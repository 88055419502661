import get from 'lodash/get';

import { Redirect } from 'sharedTypes';

const sanitizeRedirectResponse = (data: unknown): Redirect => {
  const from: string = get(data, 'from', '');
  const to: string = get(data, 'to', '');

  return { from, to };
};

export default sanitizeRedirectResponse;
