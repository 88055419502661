import ImageGroq from './ImageGroq';
import PodcastGroq from './PodcastGroq';

const PodcastsPageGroq = `{
  _id,
  _type,
  "seo": {
    "title": seoTitle,
    "description": seoDescription,
    "image": seoImage${ImageGroq}
  },
  podcasts[]-> ${PodcastGroq},
}`;

export default PodcastsPageGroq;
