import React from 'react';

import { Colors, HexColors } from 'constants/Colors';

interface Props {
  color: Colors;
  className?: string;
}

const ExploreIcon: React.FC<Props> = ({ color, className = '' }) => {
  return (
    <svg
      className={className}
      width="20px"
      height="24px"
      viewBox="0 0 20 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>Explore Icon.</desc>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={HexColors[color]}>
          <rect x="0" y="3" width="4" height="18"></rect>
          <rect x="16" y="3" width="4" height="18"></rect>
          <rect x="5" y="5" width="10" height="14"></rect>
          <rect x="1" y="0" width="2" height="2"></rect>
          <rect x="1" y="22" width="2" height="2"></rect>
          <rect x="17" y="0" width="2" height="2"></rect>
          <rect x="17" y="22" width="2" height="2"></rect>
        </g>
      </g>
    </svg>
  );
};

export default ExploreIcon;
