import { Img } from 'components/base';
import Language from 'constants/Language';
import { useRouter } from 'next/router';

const ExitPreviewButton = () => {
  const router = useRouter();
  const exitPreviewRoute = `/api/exit-preview?slug=${router.asPath}`;
  return (
    <a
      href={exitPreviewRoute}
      className="z-exit-preview flex color-gray text-decoration-none no-display-in-print"
      style={{
        position: 'relative',
        fontSize: 'small',
        width: 'fit-content',
      }}
    >
      <Img
        alt={Language.t('Global.exitPreviewMode')}
        src={`/assets/images/sanity-cms.svg`}
        style={{ width: 15, height: 15, marginRight: 5 }}
      />
      <span className="graebenbach">
        {Language.t('Global.exitPreviewMode')}
      </span>
    </a>
  );
};

export default ExitPreviewButton;
