import { Router } from 'next/router';
import { useState, useEffect } from 'react';
import { Redirect } from 'sharedTypes';
import { calculateFormat, formatSeconds } from 'utils/dateRoundedToDat';
import { removeTrailingSlash } from 'utils/slugify';

export const useCurrentURL = () => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  return url;
};

type DurationTimes = {
  playedDuration: number;
  totalDuration: number;
};

export const timestampFormat = (a: string, b: string) => `${a} / ${b}`;

export const useFormattedTimeStamp = ({
  playedDuration,
  totalDuration,
}: DurationTimes) => {
  const timeFormat = calculateFormat(totalDuration);

  const prettyPlayedDuration = formatSeconds(playedDuration, timeFormat);
  const prettyTotalDuration = formatSeconds(totalDuration, timeFormat);

  return timestampFormat(prettyPlayedDuration, prettyTotalDuration);
};

export const useWindowWidth = () => {
  const [windowSize, setWindowSize] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useClientSideRedirection = (baseData: any, router: Router) => {
  useEffect(() => {
    const redirectsMap = (
      baseData?.globalSettings?.redirects as Redirect[]
    )?.reduce(
      (acc, r) => ({
        ...acc,
        [removeTrailingSlash(r.from)]: r.to,
      }),
      {} as Record<string, string>
    );

    if (redirectsMap?.[router.asPath]) {
      router.push(redirectsMap[router.asPath]);
    }
  }, [baseData.globalSettings?.redirects, router]);
};
