import ImageGroq from 'groq/ImageGroq';

const PodcastEpisodeLinkGroq = `
  { 
    "id": _id, 
    slug, 
    "date": _createdAt,
    title, 
    dek, 
    "image": image${ImageGroq}, 
    podcast,
    releaseDate
  }
`;

export default PodcastEpisodeLinkGroq;
