import { useSiteSettings } from 'providers/SiteSettingsProvider';
import { useRouterLoading } from 'utils/next';

export const useIsFiction = () => {
  const { componentProps } = useSiteSettings();
  const loading = useRouterLoading();

  if (loading) {
    return false;
  }

  const isFiction = componentProps?.article?.meta?.isFiction;

  return isFiction;
};
