import ImageGroq from './ImageGroq';
import ArticleOrPodcastEpisodeLinkGroq from './ArticleOrPodcastEpisodeLinkGroq';
import { ColumnGroq } from './ColumnGroq';

const ArticleContentGroq = (fieldName: string) => `
  ${fieldName}[] {
    'id': _key,
    'type': _type,
    'slideshowImages': slideshowImages[]${ImageGroq},
    'image': image${ImageGroq},
    column->${ColumnGroq},
    'variant': type,
    articles[]->${ArticleOrPodcastEpisodeLinkGroq},
    "linkedPodcastEpisode": podcastEpisode->{
      title,
      description,
      releaseDate,
      slug,
      image${ImageGroq},
      ...content[_type == "podcastPlayer"] {
        podcastAudioLink,
      }[0],
      podcast->{
        "applePodcastLink": subscribeLink,
        spotifyPodcastLink,
        rssLink,
        amazonPodcastLink,
        amazonPodcastText,
        title,
        "seriesSlug": slug,
        image${ImageGroq}
      }
    },
    ...
  }
`;

export default ArticleContentGroq;
