import memoize from 'lodash/memoize';
import sanitizeAuthorResponse from './sanitizeAuthorResponse';

import { AuthorLink } from 'sharedTypes';

const defaultData: unknown[] = [];

export default memoize((response: unknown): AuthorLink[] => {
  const data = Array.isArray(response) ? response : defaultData;

  return data.map(sanitizeAuthorResponse);
});
