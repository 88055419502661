import Head from 'next/head';
import Script from 'next/script';
import type { AppProps } from 'next/app';
import 'what-input';
import '../styles/styles.scss';
import { AllProviders } from '../providers/AllProviders';
import AppWrapper from '../views/AppWrapper';
import { GTM_ID } from '../lib/GTM';
import { PageProps, useRouterLoading } from 'utils/next';
import { TabletLogoLoader } from 'components/TabletLogoLoader';
import ExitPreviewButton from 'components/ExitPreviewButton';
import EditArticleButton from 'components/EditArticleButton';
import { useClientSideRedirection } from 'src/hooks';
import EditPodcastEpisodeButton from 'components/EditPodcastEpisodeButton';

// NEXT_TODO: add SITE_ENV variable to vars / config
interface Props extends AppProps {
  err: any;
  pageProps: PageProps;
}

const App = ({ Component, pageProps, router, err }: Props) => {
  const { baseData: baseDataTemp, preview, ...componentProps } = pageProps;

  const loading = useRouterLoading();

  const baseData = { ...baseDataTemp, componentProps };

  useClientSideRedirection(baseData, router);

  return (
    <>
      <GoogleTagManager />
      <TwitterPixel />
      <Bloomerang />
      <Retention />

      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content={'/robots.txt'} />
      </Head>
      <AllProviders baseData={baseData} preview={preview}>
        <AppWrapper router={router}>
          {loading && <TabletLogoLoader />}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            {preview && componentProps.article && (
              <EditArticleButton articleId={componentProps.article.id} />
            )}
            {preview && componentProps.podcastEpisode && (
              <EditPodcastEpisodeButton
                podcastId={componentProps.podcastEpisode.id}
              />
            )}
            {preview && <ExitPreviewButton />}
          </div>

          <Component {...componentProps} err={err} />
        </AppWrapper>
      </AllProviders>

      <Script
        async
        src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        strategy="afterInteractive"
      />

      <Script
        type="text/javascript"
        src="https://www.sefaria.org/linker.js"
        strategy="afterInteractive"
      />
    </>
  );
};

export default App;

const GoogleTagManager = () => (
  <>
    {/* Google Tag Manager - Global base code */}
    <Script
      id="gtm-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
      }}
    />
  </>
);

// const FacebookPixel = () => (
//   <>
//     {/* Facebook Pixel Code */}
//     <Script
//       id="fb-pixel"
//       strategy="afterInteractive"
//       dangerouslySetInnerHTML={{
//         __html: `
//         !function(f,b,e,v,n,t,s)
//         {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
//         n.callMethod.apply(n,arguments):n.queue.push(arguments)};
//         if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
//         n.queue=[];t=b.createElement(e);t.async=!0;
//         t.src=v;s=b.getElementsByTagName(e)[0];
//         s.parentNode.insertBefore(t,s)}(window, document,'script',
//         'https://connect.facebook.net/en_US/fbevents.js');
//         fbq('init', '457427518593043');`,
//       }}
//     />
//   </>
// );

const TwitterPixel = () => (
  <>
    {/* Twitter universal website tag code */}
    <Script
      id="twitter-pixel"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
        twq('config','o6a1r');`,
      }}
    />
  </>
);

const Bloomerang = () => (
  <>
    <Script
      type="text/javascript"
      src="https://api.bloomerang.co/v1/WebsiteVisit?ApiKey=pub_95eefabc-db01-11ee-a499-0a3287177f03"
      strategy="afterInteractive"
    />
  </>
);

const Retention = () => (
  <>
    <Script
      id="retention"
      type="text/javascript"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          !function(){
            var geq = window.geq = window.geq || [];
            if(geq.initialize) return;
            if (geq.invoked) {
              if (window.console && console.error) {
                console.error("GE snippet included twice.");
              }
              return;
            }
            geq.invoked = true;
            geq.methods = ["page", "suppress", "track", "doNotTrack", "trackOrder", "identify", "addToCart", "callBack", "event"];
            geq.factory = function(method) {
              return function() {
                var args = Array.prototype.slice.call(arguments);
                args.unshift(method);
                geq.push(args);
                return geq;
              };
            };
            for (var i = 0; i < geq.methods.length; i++) {
              var key = geq.methods[i];
              geq[key] = geq.factory(key);
            }
            geq.load = function(key) {
              var script = document.createElement("script");
              script.type = "text/javascript";
              script.async = true;
              if (location.href.includes("vge=true")) {
                script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js?v=" + Math.random();
              } else {
                script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js";
              }
              var first = document.getElementsByTagName("script")[0];
              first.parentNode.insertBefore(script, first);
            };
            geq.SNIPPET_VERSION = "1.6.1";
            geq.load("350H9075");
          }();
        `,
      }}
    />
  </>
);
