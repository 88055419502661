import * as Cheerio from 'cheerio';
import { Column } from './components/Meta';
import { Paginated } from './types';

export type CheerioRoot = ReturnType<typeof Cheerio.load>;
export type CheerioElement = Parameters<typeof Cheerio.load>[0];

export interface SanityReference {
  _ref: string;
}

export type Keyed<T> = T & { _key: string };

export type AdBlock = {
  id: string;
  _type: 'ad';
  type: 'ad';
  size: AdSizeStory | AdSizeLayout;
};

export type AdLayout = {
  id: string;
  type: 'ad';
  size: AdSizeLayout;
};

export enum AdSizeStory {
  '300x300' = '300x300',
  '300x600' = '300x600',
  '320x50' = '320x50',
}

export enum AdSizeLayout {
  '728x90' = '728x90',
  '970x90' = '970x90',
  '970x250' = '970x250',
}

export interface Article {
  _type: 'article';
  title: string;
  slug: string;
  id: string;
  seo: SeoSettings;
  meta: ArticleMeta;
  hero: ArticleHero;
  content: ArticleContent;
  featuredContent: ArticleFeaturedContent;
  legacy: ArticleLegacyData;
  notes: ArticleNotes;
  section: SectionLink;
  collection: Collection;
  column: Column;
}

export interface ColumnContent {
  _type: 'column';
  type: 'column';
  column: Column;
}

export type ArticleBody =
  | ArticleTextContent
  | ColumnContent
  | ArticleRecipeContent
  | ArticleInsetImageSlideshow
  | ArticleInsetImage
  | ArticlePullQuote
  | AudioPlayerType
  | EmbedBlock
  | RelatedStories
  | RelatedStoriesByCollectionMark
  | Divider
  | LegacyRecipeBlock
  | AdBlock
  | NewsletterSignup
  | DonateButton
  | CollectionEmbed
  | ArticleAccordion
  | ShareButtonEmbed;

export interface ArticleContent {
  body: ArticleBody[] | CheerioElement | string;
  slideshow?: LegacySlideshow | null;
  podcast?: LegacyPodcast | null;
  recipe?: LegacyRecipe | null;
}

export interface ArticleFeaturedContent {
  featuredFact: FeaturedFact | null;
  featuredImage?: Image;
  featuredQuote: FeaturedQuote | null;
}

export interface ArticleHero {
  heroImage: Image;
  heroType: ArticleHeroType;
  dek: string;
  imageHotspots?: ImageHotspot[];
}

export enum ArticleHeroType {
  FULL_WIDTH_IMAGE_WITH_HALF_WIDTH_HEADER = 'full-width-image-with-half-width-header', // Horizontal Image (Sanity)
  HALF_WIDTH_HEADER_WITH_IMAGE = 'half-width-header-with-image', // Small Image (Sanity)
  NO_IMAGE = 'no-image', // No Image (Sanity)
  NO_IMAGE_CENTERED_TEXT = 'no-image-centered-text', // No Image, Centered Text(Sanity)
  FULL_WIDTH_HEADER_WITH_IMAGE = 'full-width-header-with-image', // Vertical Image (Sanity)
}

export interface ArticleAccordion {
  id: string;
  _type: 'accordion';
  type: 'accordion';
  headerText: string;
  richTextBlock: BlockRichText;
}

export interface ArticleInsetImage {
  id: string;
  _type: 'insetImage';
  type: 'insetImage';
  image: Image;
  position: Position;
  url?: string;
  rules?: Rules;
}

export interface ArticleInsetImageSlideshow {
  id: string;
  _type: 'insetImageSlideshow';
  type: 'insetImageSlideshow';
  slideshowImages: Image[];
  position: Position;
}
export interface ArticleLegacyData {
  isLegacy: boolean;
  legacyPathname: string;
  legacyBodyContent?: string;
}

export interface ArticleLink extends DocumentLink {
  _type: 'article';
  id?: string;
  title: string;
  isFiction?: boolean;
  featuredImage?: Image;
  heroImage?: Image;
  mobileHeroImage?: Image;
  section: SectionLink;
  authors: AuthorLink[];
  excerpt?: string;
  releaseDate: string;
  slug: string;
  quote?: FeaturedQuote;
  fact?: FeaturedFact;
  dek?: string;
  brief?: string;
  podcast?: Podcast;
  teaserLink?: TeaserLink;
}

export interface ImageHotspot {
  _type: 'hotspot';
  hotspotText: string;
  hotspotLink: string;
  x: number;
  y: number;
  useDarkModeColorScheme?: boolean;
}
export interface RecentArticleLink extends DocumentLink {
  _type: 'article';
  id?: string;
  title: string;
  isFiction?: boolean;
  section: SectionLink;
  authors: AuthorLink[];
  releaseDate: string;
  slug: string;
}

export interface FeatureArticlePageLink extends DocumentLink {
  _type: 'featureArticlePage';
  id?: string;
  title: string;
  featuredImage?: Image;
  heroImage?: Image;
  mobileHeroImage?: Image;
  useArticleHeaderHero?: boolean;
  authors: AuthorLink[];
  section?: SectionLink;
  excerpt?: string;
  releaseDate: string;
  slug: string;
  quote?: FeaturedQuote;
  fact?: FeaturedFact;
  dek?: string;
  brief?: string;
  teaserLink?: TeaserLink;
}

export type ArticleLinkType = ArticleLink | FeatureArticlePageLink;

export interface NewsletterSignup {
  _type: 'newsletterSignup';
  type: 'newsletterSignup';
  id: string;
  text: PortableText;
  variant: 'black' | 'red';
}

interface LegacyRecipeMigration {
  markedComplete?: boolean;
  markedToArchive?: boolean;
}

export interface ArticleMeta {
  date: string;
  authors: AuthorLink[];
  section: SectionLink;
  tags: TagLink[];
  excerpt: string;
  brief: string;
  isFiction?: boolean;
  isHighDefImage?: boolean;
  createdAt: string;
  releaseDate: string;
  updatedAt: string;
  articleType: string;
  relatedRecipes?: RecipeArticleLink[];
  relatedRecipesDisplay?: string;
  legacyRecipeMigration: LegacyRecipeMigration;
}

export interface ArticleNotes {
  editorsNote: ArticleTextContent[];
  endNote: ArticleTextContent[];
  heroType?: ArticleHeroType;
}

export interface ArticlePullQuote {
  _type: 'pullQuote';
  type: 'pullQuote';
  id: string;
  position: Position;
  text: string;
}

interface AudioPlayerBase {
  _type: 'audioPlayer' | 'podcastPlayer';
  _key: string;
  type: 'audioPlayer' | 'podcastPlayer';
  id: string;

  podcastAudioLink?: never | string;
}

interface PodcastAudio {
  title: string;
  description: string;
  releaseDate: string;
  podcastAudioLink: string;
  image: Image | null;
  slug: string;
  podcast: {
    applePodcastLink?: string;
    spotifyPodcastLink?: string;
    rssLink?: string;
    title: string;
    image: Image;
    seriesSlug: string;
  };
}

interface AudioSnippetPlayer extends AudioPlayerBase {
  title: string;
  mp3Link: string;
  linkedPodcastEpisode: never;
}

export interface LinkedPodcastPlayer extends AudioPlayerBase {
  title?: never;
  mp3Link?: never;
  linkedPodcastEpisode: PodcastAudio;
}

export interface SelfPodcastPlayer extends AudioPlayerBase {
  title?: never;
  mp3Link?: never;
  linkedPodcastEpisode: never;
  podcastAudioLink: string;
}
export type AudioPlayerType =
  | AudioSnippetPlayer
  | LinkedPodcastPlayer
  | SelfPodcastPlayer;

export interface ArticleRecipeContent {
  id: string;
  _type: 'recipeBlock';
  type: 'recipeBlock';
  variant: RecipeVariant;
  title: string;
  includedRecipes: Recipe[];
}

export interface ArticleTextContent {
  id: string;
  _type: 'block';
  type: 'block';
  // children: PortableText;
  children: Span[];
  style: string;
}

// TO-DO: Extend AuthorLink to provide Articles[] in this array
export interface Author extends AuthorLink {
  articles: ArticleLink[];
}

export interface AuthorLink {
  _id: string;
  _type: 'author';
  firstName: string;
  lastName: string;
  slug: string;
  legacySlug?: string;
  bio?: PortableText | string;
}

export type Block = BlockContactUs | BlockRichText | BlockTitle | BlockWhoWeAre;

export interface BlockContactUs {
  type: 'contactUsBlock';
  id: string;
  title: string;
  company: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
}

export interface BlockRichText {
  type: 'richTextBlock';
  id: string;
  style: string;
  text: PortableText[];
  sansSerif: boolean;
}

export interface BlockTitle {
  type: 'titleBlock';
  id: string;
  style: string;
  title: PortableText[];
  borderTop: boolean;
  sansSerif: boolean;
}

export interface BlockWhoWeAre {
  type: 'whoWeAreBlock';
  id: string;
  teamMembers: TeamMember[];
}

export type ContentTab = {
  title: string;
  tag: TagLink;
  layout: 'a' | 'b' | 'c' | 'd';
  articles: ArticleLink[];
};

export interface CollectionEmbed {
  _type: 'collectionEmbed';
  type: 'collectionEmbed';
  id: string;
  showCollection: boolean;
  position: ExtendedPosition;
}

export interface Divider {
  _type: 'divider';
  type: 'divider';
  id: string;
  variant: 'dotted-rule' | 'space' | 'rule' | 'short-rule';
}

export interface DonateButton {
  _type: 'donateButton';
  type: 'donateButton';
  id: string;
  headline: string;
  subheadline: PortableText;
  ctaText: string;
  ctaLink: string;
  useDarkModeColorScheme?: boolean;
}

export interface DonationPopup {
  isActive: boolean;
  title: string;
  description: PortableText;
  link: string;
  amounts: DonationAmount[];
}

export interface DonationAmount {
  label: string;
  param: string;
}

export interface EmailSignUpPopup {
  isActive: boolean;
  title: string;
  description: PortableText;
  subscriptionLink: string;
  successResponse: string;
}

export enum ElementType {
  TAG = 'tag',
  TEXT = 'text',
}

export interface EmbedBlock {
  id: string;
  _type: 'embedBlock';
  type: 'embedBlock';
  url?: string;
  title?: string;
  html?: string;
  size?: 'large' | 'medium' | 'small-left' | 'small-right';
  caption?: string;
  attribution?: string;
}

export interface Encyclopedia {
  title: string;
  description: PortableText[];
  stickerImage: Image;
  stickerLink: string;
}

export interface EncyclopediaTerm {
  term: string;
  pronunciation: string;
  typeOfWord: string;
  definition: string;
  link: string;
}

export interface ErrorCode {
  code: string;
  title: string;
  details: string;
}

export interface FeaturedFact {
  fact: string;
  subtitle: string;
}

export interface FeaturedQuote {
  attribution: string[];
  quote: string;
}

export interface ShareButtonEmbed {
  _type: 'shareButton';
  type: 'shareButton';
  id: string;
  anchorLinkHash: string;
}

export interface FrontPage {
  _type: 'frontPage';
  modules: LayoutModule[];
  holiday?: Keyed<SanityReference>;
  featuredImage?: Image;
  featuredLink?: string;
  showTagline?: boolean;
  taglineText?: string;
  showAppButton?: boolean;
}

export interface GenericPage {
  _type: 'genericPage';
  title: string;
  slug: string;
  blocks: Block[];
  seo: SeoSettings;
}

export interface FeatureArticlePage {
  _type: 'featureArticlePage';
  title: string;
  hideHeadline?: boolean;
  dek?: string;
  slug: string;
  authors?: AuthorLink[];
  section?: SectionLink;
  releaseDate?: string;
  heroImage: Image;
  mobileHeroImage?: Image;
  useArticleHeaderHero?: boolean;
  isHighDefImage?: boolean;
  featuredContent: ArticleFeaturedContent;
  content: ArticleContent;
  seo: SeoSettings;
  backgroundColor: ColorInput;
  progressBarColor: ColorInput;
  bodyTextColor: ColorInput;
  footerLogoTheme: 'dark' | 'light' | 'blue';
}

export interface ColorInput {
  _type: 'color';
  hex: string;
}

export interface BaseData {
  globalSettings: GlobalSettings;
  orderedMenuSections: SectionMenuLink[];
  totalSections: number;
  latestArticles: ArticleLink[];
  recentArticlesBySection: ArticleLink[][];
  redirects: Redirect[];
  componentProps?: any;
}

export type Slugs = { slug: string }[];

export type ArticlesByDate = { [key: string]: ArticleLink[] };

export interface GlobalSettings {
  teamMembers: TeamMember[];
  membershipLink: string;
  tabletAppButtonText?: string;
  tabletAppLink?: string;
  newJewishEncyclopediaLink: string;
  donationPopup: DonationPopup;
  emailSignUpPopup: EmailSignUpPopup;
  sectionPageOrder: Keyed<SanityReference>[];
  sectionScrollCTA?: string;
}

interface DocumentLink {
  _type: string;
  id?: string;
  title: string;
  slug: string;
}

interface TeaserLink {
  enabled: boolean;
  url: string;
}

export type GridItem =
  | ArticleLink
  | PodcastEpisodeLink
  | RecipeArticleLink
  | FeatureArticlePageLink;

export interface Holiday {
  _type: 'holiday';
  id: string;
  title: string;
  slug: string;
  holidayBanner?: Image;
  heroIllustration: Image;
  heroIllustrationMobile?: Image;
  heroLayout: HolidayHeroLayout;
  tag: TagLink;
  featuredArticles: ArticleLink[];
  briefDescription: string;
  detailedDescription: PortableText[];
  aboutHolidayArticle?: ArticleLink;
  startDate: string;
  endDate: string;
  contentTabs: Keyed<ContentTab>[];
  seo: SeoSettings;
  recipesSeo: SeoSettings;
  recipeIllustration?: Image;
  recipesIntro?: PortableText;
  illustrationIsTransparent?: boolean;
  relatedRecipes: RecipeArticleLink[];

  /* TO-DO: [DEPRECATED] Once Holidays v2 is launched this can be removed */
  featuredArticle: ArticleLink;
  featuredArticleTeaserType: StoryTeaser;
  stories1: HolidayStories;
  stories2: HolidayStories;
  stories3: HolidayStories;
}

export type HolidayHeroLayout = 'a' | 'b' | 'c';

export type HolidayLink = Pick<
  Holiday,
  | 'id'
  | 'title'
  | 'slug'
  | 'startDate'
  | 'endDate'
  | 'briefDescription'
  | 'detailedDescription'
>;

export type HolidayBanner = Pick<
  Holiday,
  '_type' | 'id' | 'slug' | 'holidayBanner'
>;

export interface Image {
  src: string;
  credit?: string;
  rteCredit?: PortableText[];
  caption?: string;
  alt?: string;
  crop?: ImageCrop;
  hotspot?: object;
  id: string;
  metadata?: {
    dimensions: ImageDimensions;
  };
}

export interface ImageDimensions {
  _type?: string;
  aspectRatio?: number;
  width: number;
  height: number;
}

export interface ImageCrop {
  bottom?: number;
  left?: number;
  top?: number;
  right?: number;
}
export interface Ingredient {
  quantity: string;
  unit: string;
  details: string;
}

export interface Column {
  _type: 'column';
  type: 'column';
  _id: string;
  image: Image;
  title: string;
  description: string;
  slug: string;
}

export interface ColumnLayout {
  type: 'columnLayout';
  id: string;
  column: Column;
  articles: ArticleLink[];
}

export interface ColumnistsPage {
  type: 'columnistsPage';
  id: string;
  description?: string;
}

export interface ColumnistLayout {
  title: string;
  description: string;
  slug: string;
  image: Image;
  article: ArticleLink;
}
export interface LayoutA {
  type: 'a';
  id: string;
  alignment: 'left-to-right' | 'right-to-left';
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
  widgets: Widget[];
}

export interface LayoutB {
  type: 'b';
  id: string;
  alignment: 'left-to-right' | 'right-to-left';
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
  article3: ArticleLinkType;
  article3TeaserType: StoryTeaser;
  widgets: Widget[];
}

export interface LayoutC {
  type: 'c';
  id: string;
  alignment: 'left-to-right' | 'right-to-left';
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
  article3: ArticleLinkType;
  article3TeaserType: StoryTeaser;
  article4: ArticleLinkType;
  article4TeaserType: StoryTeaser;
  widgets: Widget[];
}

export interface LayoutD {
  type: 'd';
  id: string;
  alignment: 'left-to-right' | 'right-to-left';
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
  article3: ArticleLinkType;
  article3TeaserType: StoryTeaser;
  article4: ArticleLinkType;
  article4TeaserType: StoryTeaser;
  article5: ArticleLinkType;
  article5TeaserType: StoryTeaser;
  widgets: Widget[];
}

export interface LayoutE {
  type: 'e';
  id: string;
  alignment: 'left-to-right' | 'right-to-left';
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  widgets: Widget[];
}

export interface LayoutF {
  type: 'f';
  id: string;
  alignment: 'left-to-right' | 'right-to-left';
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
  article3: ArticleLinkType;
  article3TeaserType: StoryTeaser;
  article4: ArticleLinkType;
  article4TeaserType: StoryTeaser;
  article5: ArticleLinkType;
  article5TeaserType: StoryTeaser;
  widgets: Widget[];
}

export interface LayoutG {
  type: 'g';
  id: string;
  alignment: 'left-to-right' | 'right-to-left';
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
  article3: ArticleLinkType;
  article3TeaserType: StoryTeaser;
  article4: ArticleLinkType;
  article4TeaserType: StoryTeaser;
  widgets: Widget[];
}

export interface LayoutH {
  type: 'h';
  id: string;
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
  article3: ArticleLinkType;
  article3TeaserType: StoryTeaser;
  article4: ArticleLinkType;
  article4TeaserType: StoryTeaser;
}

export interface LayoutI {
  type: 'i';
  id: string;
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
  article3: ArticleLinkType;
  article3TeaserType: StoryTeaser;
  widget1: Widget;
  widget2: Widget;
}

export interface LayoutJ {
  type: 'j';
  id: string;
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
  article3: ArticleLinkType;
  article3TeaserType: StoryTeaser;
  article4: ArticleLinkType;
  article4TeaserType: StoryTeaser;
  article5: ArticleLinkType;
  article5TeaserType: StoryTeaser;
  article6: ArticleLinkType;
  article6TeaserType: StoryTeaser;
}

export interface LayoutK {
  type: 'k';
  id: string;
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
  article3: ArticleLinkType;
  article3TeaserType: StoryTeaser;
  article4: ArticleLinkType;
  article4TeaserType: StoryTeaser;
}

export interface LayoutL {
  type: 'l';
  id: string;
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
  article3: ArticleLinkType;
  article3TeaserType: StoryTeaser;
}

export interface LayoutM {
  type: 'm';
  id: string;
  alignment: 'right-aligned' | 'left-aligned';
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
}

export interface LayoutN {
  type: 'n';
  id: string;
  alignment: 'right-aligned' | 'left-aligned';
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
}

export interface LayoutO {
  type: 'o';
  id: string;
  article1: ArticleLinkType;
  article1TeaserType: StoryTeaser;
  article2: ArticleLinkType;
  article2TeaserType: StoryTeaser;
}

export interface LayoutHero1 {
  type: 'hero1';
  article1: ArticleLinkType;
  id: string;
}

export interface LayoutHero2 {
  type: 'hero2';
  article: ArticleLinkType;
  showImage: boolean;
  showIntroText: boolean;
  id: string;
}

export interface LayoutHero3 {
  type: 'hero3';
  article: ArticleLinkType;
  showIntroText: boolean;
  id: string;
}

export interface LayoutHero4 {
  type: 'hero4';
  banner: {
    title: string;
    description?: string;
    image: Image;
    slug: string;
  };
  article1: ArticleLinkType;
  article2: ArticleLinkType;
  article3: ArticleLinkType;
  id: string;
}

export interface LayoutHolidayHero {
  type: 'holidayHero';
  holiday: HolidayLink;
  holidayHeroColor: 'black' | 'red';
  article1: ArticleLinkType;
  article2: ArticleLinkType;
  article3: ArticleLinkType;
  article4: ArticleLinkType;
  article5?: ArticleLinkType;
  id: string;
}

export interface LayoutNewsletterWidget {
  type: 'newsletterWidget';
  id: string;
  text?: PortableText;
  variant: 'black' | 'red';
}

export interface Collection {
  type: 'collection';
  id: string;
  title: string;
  description?: string;
  image?: Image;
  slug: string;
  featuredArticles?: ArticleLink[];
}

export interface CollectionsPage {
  type: 'collectionsPage';
  id: string;
  description?: string;
  collection1?: Collection;
  collection2?: Collection;
  collection3?: Collection;
  collection4?: Collection;
  collection5?: Collection;
  collection6?: Collection;
  collection7?: Collection;
  collection8?: Collection;
  collection9?: Collection;
  collection10?: Collection;
  collection11?: Collection;
  collection12?: Collection;
}

export interface CollectionsLayoutLibrary {
  _type: 'collectionsLayoutLibrary';
  _id: string;
  collection: Collection;
  article1: ArticleLink;
  article1TeaserType: StoryTeaser;
  article2: ArticleLink;
  article2TeaserType: StoryTeaser;
  article3: ArticleLink;
  article3TeaserType: StoryTeaser;
  article4: ArticleLink;
  article4TeaserType: StoryTeaser;
  article5: ArticleLink;
  article5TeaserType: StoryTeaser;
  article6: ArticleLink;
  article6TeaserType: StoryTeaser;
  article7: ArticleLink;
  article7TeaserType: StoryTeaser;
  article8: ArticleLink;
  article8TeaserType: StoryTeaser;
  article9: ArticleLink;
  article9TeaserType: StoryTeaser;
  article10: ArticleLink;
  article10TeaserType: StoryTeaser;
  widgets: Widget[];
}
export interface Collections4Stories {
  type: 'collections4Stories';
  id: string;
  collectionsLayoutLibrary: CollectionsLayoutLibrary;
}

export interface Collections4StoriesWidget
  extends Omit<Collections4Stories, 'type'> {
  type: 'collections4StoriesWidget';
}
export interface Collections6Stories extends Omit<Collections4Stories, 'type'> {
  type: 'collections6Stories';
  useAlternative6Collection: boolean;
}

export interface Collections6StoriesWidget
  extends Omit<Collections6Stories, 'type'> {
  type: 'collections6StoriesWidget';
}

export interface Collections8Stories extends Omit<Collections6Stories, 'type'> {
  type: 'collections8Stories';
}

export interface Collections8StoriesWidget
  extends Omit<Collections8Stories, 'type'> {
  type: 'collections8StoriesWidget';
}

export interface Collections10Stories
  extends Omit<Collections8Stories, 'type'> {
  type: 'collections10Stories';
}

export interface Collections10StoriesWidget
  extends Omit<Collections10Stories, 'type'> {
  type: 'collections10StoriesWidget';
}

export type LayoutModule =
  | LayoutA
  | LayoutB
  | LayoutC
  | LayoutD
  | LayoutE
  | LayoutF
  | LayoutG
  | LayoutH
  | LayoutI
  | LayoutJ
  | LayoutK
  | LayoutL
  | LayoutM
  | LayoutN
  | LayoutO
  | LayoutHero1
  | LayoutHero2
  | LayoutHero3
  | LayoutHero4
  | LayoutHolidayHero
  | LayoutNewsletterWidget
  | ColumnLayout
  | AdLayout
  | Collections4Stories
  | Collections4StoriesWidget
  | Collections6Stories
  | Collections6StoriesWidget
  | Collections8Stories
  | Collections8StoriesWidget
  | Collections10Stories
  | Collections10StoriesWidget;

export interface LegacyArticle {
  title: string;
  dek: string;
  heroImageSrc: string | null;
  heroImageCredit: string;
  category: string;
  tags: TagLink[];
  url: string;
  slug: string;
  section: SectionLink;
  date: Date;
  authors: AuthorLink[];
  body: string;
  bodyAsBlocks?: object[];
  slideshow: LegacySlideshow | null;
  podcast: LegacyPodcast | null;
  recipe: LegacyRecipe | null;
  seo: SeoSettings;
}

export interface LegacyHTMLSet {
  main: string;
  dependencies: {
    [key: string]: string;
  };
}

export interface LegacyItem {}

export interface LegacyParser {
  cache: { [hash: string]: LegacyParserOutput };
  clearCache: () => void;
  parse: (html: LegacyHTMLSet | null) => LegacyParserOutput | null;
  serialize: (data: LegacyArticle) => Article;
  extract: (htmlSet: LegacyHTMLSet) => LegacyArticle | null;
  decorateOutput: (data: Article) => LegacyParserOutput;
}

export interface LegacyParserOutput {
  article: Article;
  warnings: ErrorCode[];
  errors: ErrorCode[];
}

export interface LegacyPodcast {
  id: string;
  src: string;
  iframe: CheerioElement;
}

export interface LegacyRecipe {
  title: string;
  body: string;
}

export interface LegacyRecipeBlock extends LegacyRecipe {
  _type: 'legacyRecipeBlock';
  type: 'legacyRecipeBlock';
  id: string;
  html: string;
}

export interface LegacySlideshow {
  title: string;
  dek: string;
  images: LegacySlideshowImage[];
}

export interface LegacySlideshowImage {
  src: string;
  caption: string;
  credit?: string;
  alt?: string;
}

export interface PageNotFound {
  image: Image;
  description: string[];
}

export interface PodcastsPage {
  _type: 'podcastsPage';
  podcasts: Podcast[];
  seo: SeoSettings;
}

export interface Podcast {
  _type: 'podcast';
  id: string;
  slug: string;
  date: string;
  title: string;
  dek: string;
  description: string;
  credits: string[];
  subscribeLink: string;
  applePodcastLink?: string;
  spotifyPodcastLink?: string;
  rssLink?: string;
  chronologicalOrder?: boolean;
  amazonPodcastLink?: string;
  amazonPodcastText?: string;
  image: Image;
  seo: SeoSettings;
}

export interface PodcastEpisode {
  id: string;
  _type: 'podcastEpisode';
  slug: string;
  // DEPRECATED: use releaseDate
  date: string;
  releaseDate: string;
  title: string;
  dek?: string;
  description?: string;
  content: ArticleBody[];
  listenOn: { title?: string; url?: string }[];
  image: Image;
  podcast: Podcast;
  url: string;
  seo: SeoSettings;
  createdAt: string;
  updatedAt: string;
}

export interface PodcastEpisodeLink extends DocumentLink {
  _type: 'podcastEpisode';
  id: string;
  slug: string;
  date: string;
  releaseDate: string;
  title: string;
  dek?: string;
  image: Image;
  podcast: Podcast | null;
}

export interface PodcastLink extends DocumentLink {
  _type: 'podcast';
  title: string;
  slug: string;
  dek: string;
  id: string;
  image: Image;
  credits: string[];
}

export type PortableText = object[];

interface Span {
  _type: 'span';
  _key: string;
  text: string;
  marks: string[];
}

export enum Position {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  FULL_BLEED = 'full-bleed',
}

export type ExtendedPosition = Position | RecipeVariant;
export interface Recipe {
  title: string;
  ingredients: Ingredient[];
  directions: string[];
  footnotes: PortableText;
  notes: PortableText;
}

export enum RecipeVariant {
  FULL_WIDTH = 'full-width',
  SIDEBAR = 'sidebar',
}

export interface Redirect {
  to: string;
  from: string;
}

export interface RelatedStories {
  _type: 'relatedStories';
  type: 'relatedStories';
  id: string;
  header?: string;
  variant: 'small' | 'large' | 'with-image';
  articles: ArticleLink[];
}
interface RelatedStoriesByCollection {
  collectionTitle: string;
  collectionDescription?: string;
  collectionSlug: string;
  articles: ArticleLink[] | null;
}

export interface RelatedStoriesByCollectionMark
  extends RelatedStoriesByCollection {
  _type: 'relatedStoriesByCollection';
  type: 'relatedStoriesByCollection';
  id: string;
  collection: Keyed<SanityReference>;
}

export enum Rules {
  SHOW_RULES = 'show-rules',
  HIDE_RULES = 'hide-rules',
}
export interface SanityAuthor {
  _type: string;
  firstName: string;
  lastName: string;
  bio: PortableText;
  slug: string;
  legacySlug: string;
}

// TO-DO: remove this completely we should only use Author
export interface SanityAuthorResponse {
  firstName: string;
  lastName: string;
  date: Date;
  url?: string; //TO-DO: when url is added to this field, make this not optional.
}

export interface Section {
  _id: string;
  _type: 'section';
  title: string;
  images: {
    dark: Image;
    light: Image;
    blue: Image;
  };
  slug: string;
  modules: LayoutModule[];
  sideNavOrder: number;
  hideInSideNav: boolean;
  seo: SeoSettings;
}

export interface HolidaysSection extends Section {
  holidays: HolidayLink[];
}

export type SectionLink = Pick<
  Section,
  '_id' | '_type' | 'title' | 'images' | 'slug' | 'modules' | 'hideInSideNav'
>;

export type SectionMenuLink = Pick<Section, 'title' | 'images' | 'slug'>;

export type SeoSettings = {
  title: string;
  description: string;
  image: Image;
};

export type StoryTeaser =
  | 'teaser-text-sm'
  | 'teaser-text-md'
  | 'teaser-text-lg'
  | 'teaser-text-xl'
  | 'teaser-quote'
  | 'teaser-fact'
  | 'teaser-image-text-sm'
  | 'teaser-image-text-md'
  | 'teaser-image-text-lg'
  | 'teaser-image-lightbox'
  | 'teaser-feature-xs'
  | 'teaser-feature-sm'
  | 'teaser-feature-md'
  | 'teaser-feature-lg'
  | 'teaser-feature-xl'
  | 'teaser-feature-lg-no-image'
  | 'teaser-feature-xxl'
  | 'teaser-tags-sm'
  | 'teaser-tags-md'
  | 'teaser-tags-lg'
  | 'teaser-text-intro'
  | 'teaser-image-text-intro';

export interface Tag {
  slug: string;
  id: string;
  title: string;
  articles: ArticleLink[];
}

export interface TagLink {
  _type: 'tag';
  title: string;
  slug: string;
  id: string;
}

export enum TagType {
  DIV = 'div',
  P = 'p',
  IMG = 'img',
  H2 = 'h2',
  H3 = 'h3',
  EM = 'em',
  BLOCKQUOTE = 'blockquote',
  IFRAME = 'iframe',
}

export interface TeamMember {
  _type: 'teamMember';
  avatarImage: Image;
  firstName: string;
  lastName: string;
  slug: string;
  jobTitle: string;
  bio: PortableText;
}

export interface HolidayStories {
  storiesTitle: string;
  type: 'object';
  article1: ArticleLink;
  article1TeaserType: StoryTeaser;
  article2: ArticleLink;
  article2TeaserType: StoryTeaser;
  article3: ArticleLink;
  article3TeaserType: StoryTeaser;
  tag: TagLink;
}

export type Widget =
  | WidgetColumnist
  | WidgetDonate
  | WidgetDownloadApp
  | WidgetEncyclopedia
  | WidgetEvent
  | WidgetFact
  | WidgetAuthor
  | WidgetList
  | WidgetNewsletter
  | WidgetPeopleSlideshow
  | WidgetPhotoSlideshow
  | WidgetPodcast
  | WidgetUpcomingShabbat
  | WidgetTag
  | WidgetText;

export interface WidgetDonate {
  type: 'donationWidget';
  title?: string;
  description?: string;
  ctaLink: string;
  ctaText: string;
}

export interface WidgetDownloadApp {
  type: 'downloadAppWidget';
  title: string;
  description: string;
  buttons: WidgetDownloadAppButton[];
}

export interface WidgetDownloadAppButton {
  buttonText: string;
  buttonUrl: string;
}

export interface WidgetEncyclopedia {
  type: 'encyclopediaWidget';
  encyclopediaTerm: EncyclopediaTerm;
}

export interface WidgetEvent {
  type: 'eventWidget';
  title: string;
  subtitle?: string;
  linkText: string;
  link: string;
}

export interface WidgetFact {
  type: 'factWidget';
  title?: string;
  fact: string;
  text: PortableText;
}

export interface WidgetAuthor {
  type: 'authorWidget';
  title?: string;
  variant: 'red' | 'black';
  articles: ArticleLink[];
}

export interface WidgetList {
  type: 'listWidget';
  title?: string;
  items: WidgetListItem[];
  variant: 'no-style' | 'bullet' | 'number' | 'fancy-number';
}

export interface WidgetListItem {
  text: PortableText;
  image: Image | null;
}

export interface WidgetNewsletter {
  type: 'newsletterWidget';
  variant: 'black' | 'red';
  text?: PortableText;
}

export interface WidgetPeopleSlideshowSlide {
  _key: string;
  image: Image;
  title?: string;
  subtitle?: string;
  text?: PortableText;
  attribution?: string;
}

export interface WidgetPeopleSlideshow {
  type: 'peopleSlideshowWidget';
  title: string | null;
  slides: WidgetPeopleSlideshowSlide[];
}

export interface WidgetPhotoSlideshowSlide {
  _key: string;
  image: Image;
  caption?: PortableText;
}

export interface WidgetPhotoSlideshow {
  type: 'photoSlideshowWidget';
  title?: string;
  text?: PortableText;
  slides: WidgetPhotoSlideshowSlide[];
}

export interface WidgetPodcast {
  type: 'podcastWidget';
  variant: 'red' | 'black';
  podcastEpisodes: Keyed<PodcastEpisode>[];
}

export interface WidgetUpcomingShabbat {
  type: 'shabbatWidget';
  title: string;
}

export type WidgetTag = {
  type: 'tagWidget';
  tag: Tag;
  variant: 'small' | 'medium' | 'large';
};

export interface WidgetText {
  type: 'textWidget';
  title?: string;
  text: PortableText;
  attribution?: string;
}

export interface WidgetColumnist {
  type: 'columnistWidget';
  columnists: Keyed<SanityReference>[];
  _id: string;
  _ref: string;
  title: string;
}

/* Recipes */

export interface RecipesEditorsPicks {
  title?: string;
  recipes: RecipeArticleLink[];
}

export type HolidayWithRecipes = HolidayLink & Pick<Holiday, 'relatedRecipes'>;

export interface RecipesBySeason {
  spring: RecipeArticleLink[];
  summer: RecipeArticleLink[];
  fall: RecipeArticleLink[];
  winter: RecipeArticleLink[];
}

/* The actual response from Sanity for the Recipes Page */
export interface RecipesPageData {
  seo: SeoSettings;
  editorsPicks: RecipesEditorsPicks;
  recipeIllustration?: Image;
  illustrationIsTransparent?: boolean;
  recipeTab?: RecipesEditorsPicks;
  recipeTabAdditional?: RecipesEditorsPicks;
}

/* The sanitized data for the Recipes page response */
export interface RecipesPageResponse {
  recipesPage: RecipesPageData;
  allRecipes: Paginated<RecipeArticleLink>;
  // TODO: Re-enable when tablet wants by season back up
  // seasonal: RecipesBySeason;
  byHoliday: HolidayWithRecipes[];
  foodSection: Section;
}

/* Recipes By Holiday page */

export interface RecipesByHolidayPageData {
  holiday: Pick<
    Holiday,
    | 'id'
    | 'title'
    | 'slug'
    | 'heroIllustration'
    | 'briefDescription'
    | 'recipeIllustration'
    | 'illustrationIsTransparent'
    | 'relatedRecipes'
    | 'recipesIntro'
    | 'seo'
    | 'recipesSeo'
  >;
  recipesPage: Pick<
    RecipesPageData,
    'recipeIllustration' | 'illustrationIsTransparent'
  >;
}

/* Recipes By Season page */

export interface RecipesBySeasonPageData {
  recipes: RecipeArticleLink[];
  recipesPage: Pick<
    RecipesPageData,
    'recipeIllustration' | 'illustrationIsTransparent'
  >;
}

export type RecipesPage = string;

export type Season = 'spring' | 'summer' | 'fall' | 'winter';

/* Recipe Article page */

export interface RecipeArticle {
  _type: 'recipeArticle';
  title: string;
  slug: string;
  id: string;
  seo: SeoSettings;
  meta: RecipeArticleMeta;
  content: RecipeArticleContent;
}

export interface RecipeArticleLink extends DocumentLink, RelatedRecipeMeta {
  _type: 'recipeArticle';
  _id: string;
  id?: string;
  title: string;
  featuredImage?: Image;
  heroImage?: Image;
  excerpt?: string;
  categories?: RecipeCategories;
  slug: string;
  quote?: FeaturedQuote;
  fact?: FeaturedFact;
  dek?: string;
  brief?: string;
}

export type RelatedRecipeMeta = Pick<
  RecipeArticleMeta,
  'authors' | 'recipeAuthors' | 'releaseDate'
>;

export type RelatedArticleReference = Pick<Article, 'title' | 'slug' | 'id'> & {
  section: {
    slug: string;
  };
};

export type RelatedHolidayReference = Pick<Holiday, 'title' | 'slug' | 'id'>;

export type RecipeCategorySettings = Record<string, boolean | null>;

export interface RecipeCategories {
  _type: string;
  recipeType?: RecipeCategorySettings;
  recipeDiet?: RecipeCategorySettings;
}

export interface RecipeArticleMeta {
  date: string;
  releaseDate: string;
  authors: AuthorLink[];
  recipeAuthors?: string[];
  tags: TagLink[];
  categories: RecipeCategories;
  season?: string[];
  brief: string;
  updatedAt: string;
  createdAt: string;
}

export interface RecipeArticleContent {
  intro: PortableText;
  heroImage?: Image;
  body: ArticleBody[];
  featuredImage?: Image;
  recipeParts?: RecipePart[];
  recipeYield?: string;
  recipeTime?: string;
  note?: PortableText;
  relatedArticles?: RelatedArticleReference[];
  relatedRecipes?: Keyed<RecipeArticleLink>[];
  relatedHolidays?: RelatedHolidayReference[];
}

export interface RecipePart {
  _key: string;
  recipePartTitle: string;
  recipePartSubtitle?: string;
  recipeIngredients?: RecipeIngredients;
  recipePreparation?: RecipePreparation;
}

export interface RecipeIngredients {
  ingredientHeader?: string;
  ingredients?: RecipeIngredient[];
}

export interface RecipeIngredient {
  _key: string;
  ingredientAmount?: string;
  ingredientUnit?: string;
  ingredientName: string;
}

export interface RecipePreparation {
  preparationHeader?: string;
  hideHeader?: boolean;
  recipeSteps?: RecipeStep[];
}

export interface RecipeStep {
  _key: string;
  instructions: PortableText;
  isNote?: boolean;
}
