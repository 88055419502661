import { useContext, ReactNode, createContext } from 'react';
import { useSearchState } from './searchState';

type SearchContextValue = ReturnType<typeof useSearchState>;

const SearchContext = createContext<SearchContextValue | undefined>(undefined);

export const SearchConsumer = SearchContext.Consumer;

export const useSearch = () => {
  const ctx = useContext(SearchContext);
  if (!ctx)
    throw new Error('useSearchContext must be used within a SearchProvider');
  return ctx;
};

interface SearchProps {
  children: ReactNode;
}

export const SearchProvider = ({ children }: SearchProps) => {
  const value = useSearchState();

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};
