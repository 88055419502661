import ArticleContentGroq from './ArticleContentGroq';
import ImageGroq from './ImageGroq';
import PodcastGroq from './PodcastGroq';

const PodcastEpisodeGroq = `{
  "id": _id, 
  slug, 
  releaseDate,
  "createdAt": _createdAt,
  "updatedAt": _updatedAt, 
  title, 
  dek, 
  description, 
  ${ArticleContentGroq('content')}, 
  listenOn,
  legacyArticlePathname, 
  "image": image${ImageGroq}, 
  podcast->${PodcastGroq},
  url,
  "seo": {
    "title": seoTitle,
    "description": seoDescription,
    "image": seoImage${ImageGroq}
  },
}`;

export default PodcastEpisodeGroq;
