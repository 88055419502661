import get from 'lodash/get';
import memoize from 'lodash/memoize';
import { Column } from 'sharedTypes';

export default memoize((data: unknown): Column => {
  return {
    _id: get(data, '_id', ''),
    _type: get(data, '_type', 'column'),
    image: {
      src: get(data, 'image.src', ''),
      caption: get(data, 'image.caption', ''),
      credit: get(data, 'image.credit', ''),
      id: get(data, 'image.id', ''),
      alt: get(data, 'image.alt', ''),
      metadata: get(data, 'image.metadata'),
    },
    slug: get(data, 'slug', ''),
    title: get(data, 'title', ''),
    description: get(data, 'description', ''),
    type: 'column',
  };
});
