import get from 'lodash/get';

import { Breakpoints } from 'lib/withBreakpoints';

const getSectionNavPanelWidth = () => {
  const currentBreakpoint = getCurrentBreakpoint();
  const minWidth = 270;
  if (typeof window === 'undefined') return minWidth;

  if (!currentBreakpoint) {
    return minWidth;
  }

  const windowWidth = get(window, 'innerWidth', 0);
  let width = windowWidth * 0.4;

  if (windowWidth < 900) {
    width = windowWidth * 0.4;
  } else if (windowWidth >= 900 && windowWidth < 1080) {
    width = windowWidth * 0.375;
  } else if (windowWidth >= 1080 && windowWidth < 1660) {
    width = windowWidth * 0.3;
  } else if (windowWidth >= 1660) {
    width = windowWidth * 0.225;
  }

  if (width < minWidth) {
    return minWidth;
  }

  return width;
};

const getCurrentBreakpoint = (): string | null => {
  if (typeof window === 'undefined') {
    return 'LARGE';
  }
  const currentViewportWidth: number = Math.round(window.innerWidth);

  return (
    Object.keys(Breakpoints).find(
      (key) =>
        Breakpoints[key].lowerBound <= currentViewportWidth &&
        Breakpoints[key].upperBound > currentViewportWidth,
    ) || null
  );
};

export default getSectionNavPanelWidth;
