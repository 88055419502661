import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { CollectionsPage } from 'sharedTypes';

export default memoize((data: unknown): CollectionsPage => {
  return {
    type: get(data, '_type', 'collectionsPage'),
    id: get(data, '_id', ''),
    description: get(data, 'description'),
    collection1: {
      id: get(data, 'collection1._id', ''),
      type: get(data, 'collection1._type', 'collection'),
      title: get(data, 'collection1.title', ''),
      description: get(data, 'collection1.description'),
      image: get(data, 'collection1.image'),
      slug: get(data, 'collection1.slug', ''),
    },
    collection2: {
      id: get(data, 'collection2._id', ''),
      type: get(data, 'collection2._type', 'collection'),
      title: get(data, 'collection2.title', ''),
      description: get(data, 'collection2.description'),
      image: get(data, 'collection2.image'),
      slug: get(data, 'collection2.slug', ''),
    },
    collection3: {
      id: get(data, 'collection3._id', ''),
      type: get(data, 'collection3._type', 'collection'),
      title: get(data, 'collection3.title', ''),
      description: get(data, 'collection3.description'),
      image: get(data, 'collection3.image'),
      slug: get(data, 'collection3.slug', ''),
    },
    collection4: {
      id: get(data, 'collection4._id', ''),
      type: get(data, 'collection4._type', 'collection'),
      title: get(data, 'collection4.title', ''),
      description: get(data, 'collection4.description'),
      image: get(data, 'collection4.image'),
      slug: get(data, 'collection4.slug', ''),
    },
    collection5: {
      id: get(data, 'collection5._id', ''),
      type: get(data, 'collection5._type', 'collection'),
      title: get(data, 'collection5.title', ''),
      description: get(data, 'collection5.description'),
      image: get(data, 'collection5.image'),
      slug: get(data, 'collection5.slug', ''),
    },
    collection6: {
      id: get(data, 'collection6._id', ''),
      type: get(data, 'collection6._type', 'collection'),
      title: get(data, 'collection6.title', ''),
      description: get(data, 'collection6.description'),
      image: get(data, 'collection6.image'),
      slug: get(data, 'collection6.slug', ''),
    },
    collection7: {
      id: get(data, 'collection7._id', ''),
      type: get(data, 'collection7._type', 'collection'),
      title: get(data, 'collection7.title', ''),
      description: get(data, 'collection7.description'),
      image: get(data, 'collection7.image'),
      slug: get(data, 'collection7.slug', ''),
    },
    collection8: {
      id: get(data, 'collection8._id', ''),
      type: get(data, 'collection8._type', 'collection'),
      title: get(data, 'collection8.title', ''),
      description: get(data, 'collection8.description'),
      image: get(data, 'collection8.image'),
      slug: get(data, 'collection8.slug', ''),
    },
    collection9: {
      id: get(data, 'collection9._id', ''),
      type: get(data, 'collection9._type', 'collection'),
      title: get(data, 'collection9.title', ''),
      description: get(data, 'collection9.description'),
      image: get(data, 'collection9.image'),
      slug: get(data, 'collection9.slug', ''),
    },
    collection10: {
      id: get(data, 'collection10._id', ''),
      type: get(data, 'collection10._type', 'collection'),
      title: get(data, 'collection10.title', ''),
      description: get(data, 'collection10.description'),
      image: get(data, 'collection10.image'),
      slug: get(data, 'collection10.slug', ''),
    },
    collection11: {
      id: get(data, 'collection11._id', ''),
      type: get(data, 'collection11._type', 'collection'),
      title: get(data, 'collection11.title', ''),
      description: get(data, 'collection11.description'),
      image: get(data, 'collection11.image'),
      slug: get(data, 'collection11.slug', ''),
    },
    collection12: {
      id: get(data, 'collection12._id', ''),
      type: get(data, 'collection12._type', 'collection'),
      title: get(data, 'collection12.title', ''),
      description: get(data, 'collection12.description'),
      image: get(data, 'collection12.image'),
      slug: get(data, 'collection12.slug', ''),
    },
  };
});
