import { Button } from './base';

interface Props {
  articleId: string;
}

const EditArticleButton: React.FC<Props> = ({ articleId }) => (
  <Button
    to={
      'https://tablet.sanity.studio/production/desk/articles;latestArticles;' +
      articleId
    }
    className="h100 w100 color-black"
    ariaLabel={'Edit this article in Sanity'}
    containerClassName={'itc-cushing block-important ArticleView__edit-button'}
    wrap={true}
  >
    <span className={'graebenbach linked-border-bottom-md-on-hover-red'}>
      {'Edit this Article'}
    </span>
  </Button>
);

export default EditArticleButton;
