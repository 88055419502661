import { RouteMap } from 'constants/RouteMap';
import { Podcast, PodcastEpisode } from 'sharedTypes';

const generatePodcastEpisodeUrl = (
  podcastSlug: Podcast['slug'],
  podcastEpisodeSlug: PodcastEpisode['slug']
): string => {
  if (!podcastEpisodeSlug) {
    throw new Error(
      `Could not generate url for podcast episode "${podcastEpisodeSlug}", no podcast slug defined`
    );
  }
  return `${RouteMap.PODCAST.base}${podcastSlug}/${podcastEpisodeSlug}`;
};

export default generatePodcastEpisodeUrl;
