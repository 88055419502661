import { Component, RefObject } from 'react';

import {
  AuthorLink,
  Image,
  GridItem,
  EncyclopediaTerm,
  PodcastEpisodeLink,
  TeamMember,
  Holiday,
  Podcast,
} from 'sharedTypes';

export interface Paginated<T> {
  items: T[];
  totalItems: number | undefined;
  hasNextPage: boolean;
}

export interface PaginationArgs<T> {
  after: number;
  prevTotal: number | undefined;
  prevItems: T[];
}

export type ValueOf<T> = T[keyof T];

export interface ArticleImgCarouselImage {
  width: number;
  height: number;
  carouselimage: Image;
}

export interface AuthorLinksByLetter {
  [key: string]: AuthorLink[];
}

export interface EncyclopediaTermsByLetter {
  [key: string]: EncyclopediaTerm[];
}

export interface HebrewDate {
  year: string | null;
  month: string | null;
  day: string | null;
}
export interface HolidayBySlug {
  [slug: string]: Holiday;
}

export interface PodcastBySlug {
  [slug: string]: Podcast;
}

export interface MenuLink {
  url: string;
  text: string;
  image: Image | null;
}

export interface WithPageInfo {
  hasNextPage?: boolean;
}

export interface TotalCount {
  total: number;
}

export interface ArticleStub {
  _type: 'article';
  slug: string;
  section: {
    slug: string;
  };
}

export interface PodcastEpisodeStub {
  _type: 'podcastEpisode';
  podcast: { slug: string } | null;
  slug: string;
}

export interface PodcastEpisodesPagination {
  page: number;
  total: number;
  podcastEpisodes: PodcastEpisodeLink[];
}

export interface RouteObject {
  path: string;
  exact: boolean;
  base?: string;
}

export interface SearchPagination {
  page: number;
  total: number;
  results: GridItem[];
}

export interface SecondaryMenuLink {
  url: string;
  text: string;
}

export enum Status {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  PENDING_MORE = 'PENDING_MORE',
  REJECTED = 'REJECTED',
  FULFILLED = 'FULFILLED',
}

export interface TeamMemberBySlug {
  [slug: string]: TeamMember;
}

export interface RefsObject {
  [key: string]: RefObject<HTMLDivElement>;
}

export enum Theme {
  Default = 'default',
  Black = 'black',
  Beige = 'beige',
  CollectionWhite = 'collectionWhite',
  Sand = 'sand',
}

export class View<Props extends ViewProps, State = {}> extends Component<
  Props,
  State
> {}

export interface ViewProps {
  model: any;
}

export type Maybe<T> = T | null | undefined;
