import { compareDesc } from 'date-fns';

import {
  AudioPlayerType,
  LinkedPodcastPlayer,
  PodcastEpisode,
  PodcastEpisodeLink,
  SelfPodcastPlayer,
} from 'sharedTypes';
import sanitizePodcastEpisodeResponse from './sanitizePodcastEpisodeResponse';

const defaultData: unknown[] = [];

const sanitizePodcastEpisodesResponse = (
  response: unknown
): PodcastEpisodeLink[] => {
  const data = Array.isArray(response) ? response : defaultData;

  const currentDate = new Date();

  return data.map(sanitizePodcastEpisodeResponse).filter((podcastEpisode) => {
    return compareDesc(new Date(podcastEpisode.date), currentDate) > 0;
  });
};

export default sanitizePodcastEpisodesResponse;

const contentIsSelfPodcast = (
  content: AudioPlayerType
): content is SelfPodcastPlayer => !!content.podcastAudioLink;

export const sanitizePodcastAudio = (
  content: AudioPlayerType,
  podcastEpisode?: PodcastEpisode
): AudioPlayerType => {
  if (
    content._type === 'audioPlayer' ||
    !podcastEpisode ||
    !contentIsSelfPodcast(content)
  ) {
    return content;
  }

  const sanitizedPodcast: LinkedPodcastPlayer = {
    _type: 'podcastPlayer',
    type: 'podcastPlayer',
    _key: 'asd',
    id: content.id,
    linkedPodcastEpisode: {
      title: podcastEpisode.title,
      description: podcastEpisode.description || '',
      releaseDate: podcastEpisode.releaseDate || '',
      podcastAudioLink: content.podcastAudioLink,
      image: null,
      slug: podcastEpisode.slug,
      podcast: {
        applePodcastLink: podcastEpisode.podcast.applePodcastLink,
        spotifyPodcastLink: podcastEpisode.podcast.spotifyPodcastLink,
        rssLink: podcastEpisode.podcast.rssLink,
        title: podcastEpisode.podcast.title,
        image: podcastEpisode.podcast.image,
        seriesSlug: podcastEpisode.podcast.slug,
      },
    },
  };

  return sanitizedPodcast;
};
