const slugify = (string: string): string =>
  string
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');

export default slugify;

export const removeTrailingSlash = (site: string) => {
  return site.replace(/\/$/, '');
};
