import get from 'lodash/get';
import { ColumnistLayout } from 'sharedTypes';
import sanitizeArticleLink from './sanitizeArticleLink';

const sanitizeColumnistResponse = (data: unknown): ColumnistLayout => {
  return {
    title: get(data, 'title', ''),
    slug: get(data, 'slug', ''),
    description: get(data, 'description', ''),
    image: {
      src: get(data, 'image.src', ''),
      caption: get(data, 'image.caption', ''),
      credit: get(data, 'image.credit', ''),
      id: get(data, 'image.id', ''),
      alt: get(data, 'image.alt', ''),
      metadata: get(data, 'image.metadata'),
    },
    article: sanitizeArticleLink(get(data, 'article')),
  };
};

export default sanitizeColumnistResponse;
