import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { HolidayBanner } from 'sharedTypes';

export default memoize(
  (holiday: unknown): HolidayBanner => ({
    _type: get(holiday, '_type', 'holiday'),
    id: get(holiday, 'id', ''),
    slug: get(holiday, 'slug', ''),
    holidayBanner: {
      src: get(holiday, 'holidayBanner.src', ''),
      id: get(holiday, 'holidayBanner.id', ''),
      metadata: get(holiday, 'holidayBanner.metadata'),
    },
  })
);
