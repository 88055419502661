import ImageGroq from './ImageGroq';
import { recipeTeaserGroq } from './RecipesPageGroq';

export const recipesByHolidayPageQuery = `{
  "holiday": *[_type == 'holiday' && slug == $slug]{
    id,
    title,
    slug,
    "heroIllustration": heroIllustration${ImageGroq},
    "recipeIllustration": recipeIllustration${ImageGroq},
    illustrationIsTransparent,
    relatedRecipes[]->[
      !defined(releaseDate) || releaseDate <= $currentDate
    ]{
      ${recipeTeaserGroq}
    },
    recipesIntro,
    "seo": {
      "title": seoTitle,
      "description": seoDescription,
      "image": seoImage${ImageGroq}
    },
    "recipesSeo": {
      "title": recipeSeoTitle,
      "description": recipeSeoDescription,
      "image": recipeSeoImage${ImageGroq}
    },
    briefDescription
  }[0],
  "recipesPage": *[_type == "recipesPage"]{
    "recipeIllustration": recipeIllustration${ImageGroq},
    illustrationIsTransparent,
  }[0]
}
`;
