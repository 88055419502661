import ArticleLinkGroq from './ArticleLinkGroq';
import { CollectionGroq } from './CollectionGroq';
import WidgetGroq from './WidgetGroq';

export const CollectionLayoutLibraryGroq = `{
  _id,
  _type,
  collection->${CollectionGroq},
  article1->${ArticleLinkGroq},
  article1TeaserType,
  article2->${ArticleLinkGroq},
  article2TeaserType,
  article3->${ArticleLinkGroq},
  article3TeaserType,
  article4->${ArticleLinkGroq},
  article4TeaserType,
  article5->${ArticleLinkGroq},
  article5TeaserType,
  article6->${ArticleLinkGroq},
  article6TeaserType,
  article7->${ArticleLinkGroq},
  article7TeaserType,
  article8->${ArticleLinkGroq},
  article8TeaserType,
  article9->${ArticleLinkGroq},
  article9TeaserType,
  article10->${ArticleLinkGroq},
  article10TeaserType,
  'widgets': widgets[]${WidgetGroq},
}`;
