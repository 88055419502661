import SectionLinkGroq from './SectionLinkGroq';
import ArticleContentGroq from './ArticleContentGroq';
import ImageGroq from './ImageGroq';
import AuthorLinkGroq from './AuthorLinkGroq';
import { recipeTeaserGroq } from './RecipesPageGroq';
import { ColumnGroq } from './ColumnGroq';
import HotspotGroq from './HotspotGroq';

const ArticleGroq = `{
  "id": _id,
  title,
  slug,
  "seo": {
    "title": seoTitle,
    "description": seoDescription,
    "image": seoImage${ImageGroq}
  },
  "section": section->{
    title,
    slug,
  },
  "collection": collection->{
    title,
    tag,
    description,
    slug,
    "image":image${ImageGroq}
  },
  column->${ColumnGroq},
  "meta": {
    releaseDate,
    "createdAt": _createdAt,
    "updatedAt": _updatedAt,
    authors[]->${AuthorLinkGroq},
    section->${SectionLinkGroq},
    "relatedRecipes": relatedRecipes[]->[
      !defined(releaseDate) || releaseDate <= $currentDate
    ]{
      ${recipeTeaserGroq}
    },
    relatedRecipesDisplay,
    legacyRecipeMigration {
      markedComplete,
      markToArchive
    },
    tags[]->{ title, slug },
    excerpt,
    brief,
    isFiction,
    isHighDefImage,
    articleType
  },
  "hero": {
    "heroImage": heroImage${ImageGroq},
    heroType,
    dek,
    "imageHotspots": imageHotspots.hotspots[]${HotspotGroq},
  },
  "content": { ${ArticleContentGroq('body')} },
  "featuredContent": {
    "featuredImage": featuredImage${ImageGroq},
    featuredQuote,
    featuredFact
  },
  "legacy": {
    legacyArticlePathname
  },
  "notes": {
    editorsNote,
    endNote
  }
}`;

export default ArticleGroq;
