import environmentIsProd from 'utils/environmentIsProd';
import environmentIsStaging from 'utils/environmentIsStaging';
import environmentIsLocal from 'utils/environmentIsLocal';
import environmentIsIntegration from 'utils/environmentIsIntegration';

type Flag = {
  DEV: boolean;
  STAGING: boolean;
  PROD: boolean;
};

export const Flags: { [key: string]: Flag } = {
  HOMEPAGE_LATEST_STORIES: {
    DEV: false,
    STAGING: false,
    PROD: false,
  },
  FOOTER_RECENT_ARTICLES_BY_SECTION: {
    DEV: true,
    STAGING: true,
    PROD: true,
  },
  V2_HOLIDAY_PAGE: {
    DEV: true,
    STAGING: true,
    PROD: true,
  },
  RECIPE_ARTICLES: {
    DEV: true,
    STAGING: true,
    PROD: true,
  },
};

export const isEnabled = (flag: Flag) => {
  if (environmentIsProd()) return flag.PROD;
  if (environmentIsStaging()) return flag.STAGING;
  if (environmentIsLocal() || environmentIsIntegration()) return flag.DEV;

  return false;
};
