import React, { ComponentType, useEffect, useState } from 'react';
import { Subtract } from 'utility-types';
import { onWindowResize } from 'utils/onWindowResize';

export type CurrentBreakpoint = string | null;

export interface InjectedProps {
  currentBreakpoint: string;
}

export interface Breakpoint {
  label: string;
  lowerBound: number;
  upperBound: number;
}

export interface State {
  currentBreakpoint: CurrentBreakpoint;
}

export const Breakpoints: {
  [id: string]: Breakpoint;
} = {
  EXTRA_SMALL: {
    label: 'EXTRA_SMALL',
    lowerBound: 0,
    upperBound: 640,
  },
  SMALL: {
    label: 'SMALL',
    lowerBound: 640,
    upperBound: 768,
  },
  MEDIUM: {
    label: 'MEDIUM',
    lowerBound: 768,
    upperBound: 900,
  },
  LARGE_MEDIUM: {
    label: 'LARGE_MEDIUM',
    lowerBound: 900,
    upperBound: 1080,
  },
  LARGE: {
    label: 'LARGE',
    lowerBound: 1080,
    upperBound: 1280,
  },
  EXTRA_LARGE: {
    label: 'EXTRA_LARGE',
    lowerBound: 1280,
    upperBound: 1600,
  },
  EXTRA_EXTRA_LARGE: {
    label: 'EXTRA_EXTRA_LARGE',
    lowerBound: 1600,
    upperBound: 1000000,
  },
};

const getCurrentBreakpoint = (): CurrentBreakpoint => {
  const currentViewportWidth: number = Math.round(
    typeof window !== 'undefined' ? window.innerWidth : 1024
  );

  return (
    Object.keys(Breakpoints).find(
      (key) =>
        Breakpoints[key].lowerBound <= currentViewportWidth &&
        Breakpoints[key].upperBound > currentViewportWidth
    ) || null
  );
};

const withBreakpoints = <WrappedComponentProps extends InjectedProps>(
  WrappedComponent: ComponentType<WrappedComponentProps>
) => {
  const WithBreakpoints: React.FC<
    Subtract<WrappedComponentProps, InjectedProps>
  > = (props) => {
    const [state, setState] = useState<State>({
      currentBreakpoint: getCurrentBreakpoint(),
    });

    useEffect(() => {
      const removeResizeListener = onWindowResize(checkBreakpoints);

      return () => {
        removeResizeListener();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkBreakpoints = () => {
      let currentBreakpoint = getCurrentBreakpoint();

      if (currentBreakpoint !== state.currentBreakpoint) {
        setState({ currentBreakpoint });
      }
    };

    return (
      <WrappedComponent
        {...(props as WrappedComponentProps)}
        currentBreakpoint={state.currentBreakpoint}
      />
    );
  };

  return WithBreakpoints;
};

export default withBreakpoints;
