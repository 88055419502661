import Environments from 'constants/Environments';

const environmentIsStaging = (
  simulateStagingEnvironment: boolean = false
): boolean => {
  if (simulateStagingEnvironment) return true;
  // NEXT_TODO: Check this with the SITE_ENV variable
  if (typeof window === 'undefined') return false;

  // NEXT_TODO: Ensure this check works on the Server...
  return Environments.STAGING.hostnames.includes(window.location.host);
};

export default environmentIsStaging;
