import SectionLinkGroq from './SectionLinkGroq';
import ImageGroq from './ImageGroq';

const ArticleOrPodcastEpisodeLinkGroq = `{
  "id": _id,
  title,
  excerpt,
  brief,
  authors[]->{ firstName, lastName, slug, legacySlug },
  'featuredImage': featuredImage${ImageGroq},
  'heroImage': heroImage${ImageGroq},
  section->${SectionLinkGroq},
  releaseDate,
  "createdAt": _createdAt,
  slug,
  'quote': featuredQuote, 
  'fact': featuredFact,
  dek,
  
  'date': _createdAt, 
  'image': image${ImageGroq}, 
  podcast,
}`;

export default ArticleOrPodcastEpisodeLinkGroq;
