import { Button } from './base';

interface Props {
  podcastId: string;
}

const EditPodcastEpisodeButton: React.FC<Props> = ({ podcastId }) => (
  <Button
    to={
      'https://tablet.sanity.studio/production/desk/podcastEpisodes;latestPodcastEpisodes;' +
      podcastId
    }
    className="h100 w100 color-white"
    ariaLabel={'Edit this article in Sanity'}
    containerClassName={'itc-cushing block-important ArticleView__edit-button'}
    wrap={true}
  >
    <span className={'graebenbach linked-border-bottom-md-on-hover-red'}>
      {'Edit this Article'}
    </span>
  </Button>
);

export default EditPodcastEpisodeButton;
